/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
import { Position } from './../position/position';
import { User } from '../user/user';

export enum Type {
  Offer = 'offer',
  Request = 'request',
}

export class Request {
  id: string;

  type: Type;

  headline: string;

  details: string;

  image: string;

  categoryId: number;

  price: number;

  priceUnit: string;

  fromDate: Date;

  toDate: Date;

  creationDate: Date;

  geoLocation: Position;

  street: string;

  zip: string;

  city: string;

  searchRadius: number;

  user?: User;

  constructor(type: Type = Type.Offer) {
    this.type = type;
  }

  getFromDateAsUnixTimestamp(): number {
    return Math.round(this.fromDate.getTime());
  }

  getToDateAsUnixTimestamp(): number {
    return Math.round(this.toDate.getTime());
  }

  getCreationDateAsUnixTimestamp(): number {
    return Math.round(this.creationDate.getTime());
  }
}
