import { Position } from './../../model/position/position';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export const validateAddressUrl =
  environment.restApiBase + '/v1/location/validate';

export const createMapsLinkUrl =
  environment.restApiBase + '/v1/location/maps/link';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private http: HttpClient) {}

  public validateAddress(
    address: string,
    city: string,
    zip: any
  ): Observable<Position> {
    console.log('Validating address for: %s, %s, %s', address, city, zip);

    const url =
      validateAddressUrl +
      '?address=' +
      encodeURIComponent(address) +
      '&city=' +
      encodeURIComponent(city) +
      '&zip=' +
      encodeURIComponent(zip);
    console.log('Calling %s', url);

    return this.http
      .get<Position>(url, { observe: 'response' })
      .pipe(
        retry(1),
        map((response) => {
          const position = Object.assign(new Position(), response.body);

          console.log(
            'LAT: %s; LONG: %s',
            position.getLatitude(),
            position.getLongitude()
          );

          return position;
        }),
        catchError((err: HttpErrorResponse) => {
          console.error('Error on validating address');
          console.error(err);

          if (err.error instanceof Error) {
            console.error('Client-side error occured.');
          } else {
            console.error('Server-side error occured.');
          }

          throw new Error(err?.error);
        })
      );
  }

  public createLinkToMapsApplication(
    street: string,
    city: string,
    zip: any
  ): string {
    const url =
      createMapsLinkUrl +
      '?address=' +
      encodeURIComponent(street) +
      '&city=' +
      encodeURIComponent(city) +
      '&zip=' +
      encodeURIComponent(zip);

    return url;
  }
}
