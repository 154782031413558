<div class="container">
  <div class="row">
    <h2>Die gewünschte Seite wurde leider nicht gefunden! (Code=404)</h2>
    <div>
      <p>
        Bitte nutzen Sie folgenden Link um zurück zur Startseite zu gelangen
        <a href="/" class="btn btn-sm btn-primary" type="button">
          Startseite
        </a>
      </p>
    </div>
  </div>
</div>
