import { Component } from '@angular/core';
import { SeoService } from 'src/app/services/seo/seo.service';
@Component({
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent {
  constructor(seo: SeoService) {
    // exclude the page from being indexed
    seo.excludeCurrentPageFromIndexing();
  }
}
