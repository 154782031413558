import { environment } from 'src/environments/environment';
import { UserService } from './../../../../services/user/user.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from 'src/app/model/user/user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navigation-profile-widget',
  templateUrl: './navigation-profile-widget.component.html',
  styleUrls: ['./navigation-profile-widget.component.scss'],
})
export class NavigationProfileWidgetComponent implements OnInit, OnDestroy {
  user: User;

  userSubscription: Subscription;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userSubscription = this.userService
      .getUserObservable()
      .subscribe((user) => {
        this.user = user;
        console.log('User changed: %o', user);
      });
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }
}
