export class Position {
  constructor(private latitude?: number, private longitude?: number) {}

  getLatitude(): number {
    return this.latitude;
  }

  getLongitude(): number {
    return this.longitude;
  }
}
