import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CachingService {
  constructor() {}

  addToCache(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  getFromCache(key: string): any {
    return localStorage.getItem(key);
  }

  removeFromCache(key: string) {
    localStorage.removeItem(key);
  }
}
