import { SearchResultWrapper } from './../../model/search-result-wrapper/search-result-wrapper';
import { environment } from 'src/environments/environment';
import { Position } from './../../model/position/position';
import { User } from './../../model/user/user';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Request } from './../../model/request/request';
import { SearchFilterConfiguration } from './../../model/search-filter-configuration/search-filter-configuration';
import { Injectable } from '@angular/core';
import { retry, map, catchError, take } from 'rxjs/operators';
import { StatusCodeHelper } from 'src/app/http/util/status-code/status-code-helper';
import { Observable, BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

export const searchUrlGeneral = environment.restApiBase + '/v1/search/general';
export const searchUrlDetails =
  environment.restApiBase + '/v1/requests/get/details';
export const searchUrlMyPosts = environment.restApiBase + '/v1/requests/get/my';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private searchResultSubject: BehaviorSubject<SearchResultWrapper> =
    new BehaviorSubject(null);

  private mySearchResultSubject: BehaviorSubject<SearchResultWrapper> =
    new BehaviorSubject(null);

  constructor(private http: HttpClient, private route: ActivatedRoute) {}

  findMyPosts(
    type: string,
    page = 0,
    sortOrder = null,
    clearCache = false
  ): Observable<SearchResultWrapper> {
    if (clearCache) {
      this.mySearchResultSubject.next(null);
    }

    let finalUrl = searchUrlMyPosts + `?type=${type}&page=${page}`;
    if (sortOrder) {
      finalUrl += `&sorting=${sortOrder}`;
    }

    console.log('Querying server with url: ' + finalUrl);

    this.createRequest(finalUrl).subscribe((results) =>
      this.mySearchResultSubject.next(results)
    );

    return this.mySearchResultSubject.asObservable();
  }

  findSearchResults(
    page = 0,
    sortOrder = null,
    clearCache = false
  ): Observable<SearchResultWrapper> {
    if (clearCache) {
      this.searchResultSubject.next(null);
    }

    const finalUrl =
      searchUrlGeneral +
      '?' +
      SearchFilterConfiguration.createQueryAsStringByActivatedRoute(
        this.route,
        page,
        sortOrder
      );
    console.log('Querying server with url: ' + finalUrl);

    this.createRequest(finalUrl).subscribe((results) =>
      this.searchResultSubject.next(results)
    );

    return this.searchResultSubject.asObservable();
  }

  findSearchResultDetails(id: string): Observable<Request> {
    const finalUrl = searchUrlDetails + '?id=' + id;
    console.log('Querying server with url: ' + finalUrl);

    return this.createRequestForSingleResult(finalUrl);
  }

  private createRequest(url: string): Observable<SearchResultWrapper> {
    return this.http
      .get<SearchResultWrapper>(url, { observe: 'response' })
      .pipe(
        retry(1),
        take(1),
        map((response) => {
          console.log('Checking search response for: %o', response.body);

          const searchResults: Request[] = [];
          if (StatusCodeHelper.is2XX(response)) {
            const serverResults = response?.body?.requests;

            if (serverResults) {
              for (const result of Object.values(serverResults)) {
                searchResults.push(this.createObjectFromJson(result));
              }
            }
            response.body.requests = searchResults;

            console.log(searchResults);
          } else {
            console.error(response);
            throw new Error(
              'Server returned invalid search result. Status code=' +
                response.status
            );
          }

          return response.body;
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('Handling error for search response');
          console.error(err);
          throw new Error(
            'Server returned invalid result. Status code=' + err.status
          );
        })
      );
  }

  private createRequestForSingleResult(url: string): Observable<Request> {
    return this.http.get<string>(url, { observe: 'response' }).pipe(
      retry(1),
      take(1),
      map((response) => {
        console.log('Checking search response for: %s', response);

        if (StatusCodeHelper.is2XX(response)) {
          return this.createObjectFromJson(response.body);
        }

        console.error(response);
        throw new Error(
          'Server returned invalid search result. Status code=' +
            response.status
        );
      }),
      catchError((err: HttpErrorResponse) => {
        console.log('Handling error for search response');
        console.error(err);
        throw new Error(
          'Server returned invalid result. Status code=' + err.status
        );
      })
    );
  }

  private createObjectFromJson(serverResults: any): Request {
    const serverResult = Object.assign(new Request(), serverResults);
    serverResult.user = Object.assign(new User(), (serverResults as any).user);

    if (!(serverResult.fromDate instanceof Date)) {
      serverResult.fromDate = new Date(+serverResult.fromDate);
    }
    if (!(serverResult.toDate instanceof Date)) {
      serverResult.toDate = new Date(+serverResult.toDate);
    }
    if (!(serverResult.creationDate instanceof Date)) {
      serverResult.creationDate = new Date(+serverResult.creationDate);
    }

    serverResult.geoLocation = Object.assign(
      new Position(),
      serverResult.geoLocation
    );
    return serverResult;
  }
}
