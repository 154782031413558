import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  showError(message: any, delay = 10000) {
    this.show(message, {
      classname: 'bg-danger text-light',
      delay: delay,
    });
  }

  showSuccess(message: any, delay = 6000) {
    this.show(message, {
      classname: 'bg-success text-light',
      delay: delay,
    });
  }

  showFormErrorToast() {
    this.showError('In dem Formular sind noch Fehler!', 4000);
  }

  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
