<footer class="wrapper footer container col-12 mt-4 my-md-5 pt-md-5 border-top">
  <div class="row w-100 mt-3 mt-md-0">
    <div class="col-md-3 col-lg-3">
      <img
        lazyLoad
        class="mb-2"
        src="assets/images/logos/icon_colored.svg"
        alt="Logo"
        height="90"
        width="174"
      />
      <small class="d-block mb-3 text-muted">&copy; {{ currentYear }}</small>
    </div>
    <div class="col-md-2 col-lg-3">
      <b>Über uns</b>
      <ul class="list-unstyled text-small">
        <!-- Waiting for content here
        <li>
          <a class="text-muted" routerLink="content/articles">Aktuelles</a>
        </li>-->
        <li>
          <a class="text-muted" routerLink="content/aboutus">Idee</a>
        </li>
        <li>
          <a class="text-muted" routerLink="pages/contact">Kontakt</a>
        </li>
      </ul>
    </div>
    <div class="col-md-4 col-lg-3">
      <b>Allgemeine Geschäftsbedingungen</b>
      <ul class="list-unstyled text-small">
        <li>
          <a class="text-muted" routerLink="content/terms">Abonnement</a>
        </li>
        <li>
          <a class="text-muted" routerLink="content/agb">AGB</a>
        </li>
      </ul>
    </div>
    <div class="col-md-3 col-lg-3">
      <b>Rechtliche Informationen</b>
      <ul class="list-unstyled text-small">
        <li>
          <a class="text-muted" routerLink="content/imprint">Impressum</a>
        </li>
        <li>
          <a class="text-muted" routerLink="content/datasecurity">
            Datenschutz
          </a>
        </li>
        <li>
          <a
            class="text-muted cursor-pointer"
            id="cookie-consent-settings"
            (click)="openCookieConsentDialog()"
          >
            Cookie-Einstellungen
          </a>
        </li>
        <li>
          <a class="text-muted" routerLink="content/licenses">
            Lizenzinformationen / OpenSource Software
          </a>
        </li>
      </ul>
    </div>
    <div
      class="col-12 mt-2 d-md-flex d-none justify-content-end align-items-center"
    >
      <div>
        <img
          lazyLoad
          width="440"
          height="84"
          alt="Klarna"
          src="https://cdn.klarna.com/1.0/shared/image/generic/badge/de_de/checkout/long-blue.png?width=440"
        />
      </div>
      <div class="ms-4">
        <img
          lazyLoad
          src="https://www.paypalobjects.com/webstatic/de_DE/i/de-pp-logo-100px.png"
          width="100"
          height="26"
          alt="PayPal Logo"
        />
      </div>
    </div>
  </div>
</footer>
<app-cookie-consent #consent></app-cookie-consent>
