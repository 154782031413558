import { Component } from '@angular/core';

@Component({
  selector: 'app-pictures',
  templateUrl: './pictures.component.html',
  styleUrls: ['./pictures.component.scss'],
})
export class PicturesComponent {
  firstImageLoaded = false;

  images: string[] = [];

  constructor() {
    this.images[0] = 'assets/images/general/homepage/carousel/carousel_1.avif';
    this.images[1] = 'assets/images/general/homepage/carousel/carousel_2.avif';
    this.images[2] = 'assets/images/general/homepage/carousel/carousel_3.avif';
    this.images[3] = 'assets/images/general/homepage/carousel/carousel_4.avif';
    this.images[4] = 'assets/images/general/homepage/carousel/carousel_5.avif';
    this.images[5] = 'assets/images/general/homepage/carousel/carousel_6.avif';
    this.images[6] = 'assets/images/general/homepage/carousel/carousel_7.avif';
  }

  imageLoaded(loaded: boolean): void {
    this.firstImageLoaded = loaded;
  }
}
