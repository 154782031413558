import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'linkRenderer',
})
export class LinkRendererPipe implements PipeTransform {
  /**
   * Adds the base url to a given link
   */
  transform(link: string): unknown {
    if (link.startsWith('http') || link.indexOf('base64') > 0) {
      return link;
    }

    let baseUrl = environment.restApiBase;
    if (baseUrl.endsWith('/')) {
      baseUrl = baseUrl.substr(0, baseUrl.length - 2);
    }
    if (!link.startsWith('/')) {
      link = '/' + link;
    }
    return baseUrl + link;
  }
}
