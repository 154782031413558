import { Observable } from 'rxjs';
import { SubscriptionType } from './../../model/subscription/subscription-type.enum';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { StatusCodeHelper } from 'src/app/http/util/status-code/status-code-helper';
import { environment } from 'src/environments/environment';

export const createOrderUrl =
  environment.restApiBase + '/v1/payment/create/orderform/';

export const createConfirmationUrl =
  environment.restApiBase + '/v1/payment/create/confirmationform/';

export const fetchPaymentConfigurationUrl =
  environment.restApiBase + '/v1/payment/get/active/';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  public createPaymentForm(
    type: SubscriptionType,
    paymentProvider: 'klarna' | 'paypal' | 'adyen',
    paymentOption: string
  ): Observable<string> {
    return this.http
      .post(createOrderUrl + paymentProvider + '/' + paymentOption, type, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          console.log(
            'Checking create payment form response for: %o',
            response
          );
          if (StatusCodeHelper.is2XX(response)) {
            return (response.body as any).html_snippet + '';
          }
          throw new Error('Invalid response from server');
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('Handling error for create payment form response');
          console.error(err);
          throw new Error(err?.error);
        })
      );
  }

  public createConfirmationForm(
    orderId: string,
    paymentProvider: 'klarna' | 'paypal' | 'adyen'
  ): Observable<string> {
    return this.http
      .post(createConfirmationUrl + paymentProvider, orderId, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          console.log(
            'Checking create confirmation form response for: %o',
            response
          );
          if (StatusCodeHelper.is2XX(response)) {
            return (response.body as any).html_snippet + '';
          }
          throw new Error('Invalid response from server');
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('Handling error for create confirmation form response');
          console.error(err);
          throw new Error(err?.error);
        })
      );
  }

  public fetchPaymentConfiguration(
    subscriptionType: SubscriptionType
  ): Observable<Record<string, unknown>> {
    return this.http
      .get<Record<string, unknown>>(
        fetchPaymentConfigurationUrl + subscriptionType,
        {
          observe: 'response',
        }
      )
      .pipe(
        map((response) => {
          console.log(
            'Checking fetch payment configuration response for: %o',
            response
          );
          if (StatusCodeHelper.is2XX(response)) {
            return response.body;
          }
          throw new Error('Invalid response from server');
        }),
        catchError((err: HttpErrorResponse) => {
          console.log(
            'Handling error for fetch payment configuration response'
          );
          console.error(err);
          throw err;
        })
      );
  }
}
