import { HttpMockRequestInterceptor } from './../mock-interceptor/http-mock-request-interceptor.interceptor';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoremIpsumService } from 'src/app/services/lorem-ipsum/lorem-ipsum.service';
import { CategoryService } from 'src/app/services/category/category.service';
import { CachingService } from 'src/app/services/caching/caching.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(
    private loremIpsumService: LoremIpsumService,
    private cachingService: CachingService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    request = request.clone({
      withCredentials: true,
    });

    return new HttpMockRequestInterceptor(
      this.loremIpsumService,
      this.cachingService
    ).intercept(request, next);
  }
}
