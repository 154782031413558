import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
/*
 as seen on https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
 */
export class PageLifecycleService {
  private hidden: string;

  private visibilityChange: string;

  private pausedTime: Date;

  private maxSleepTimeUntilRefresh = 1000 * 60 * 60 * 1; //1 hrs

  constructor() {
    // Set the name of the hidden property and the change event for visibility
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      this.hidden = 'hidden';
      this.visibilityChange = 'visibilitychange';
    } else if (typeof (document as any)?.msHidden !== 'undefined') {
      this.hidden = 'msHidden';
      this.visibilityChange = 'msvisibilitychange';
    } else if (typeof (document as any)?.webkitHidden !== 'undefined') {
      this.hidden = 'webkitHidden';
      this.visibilityChange = 'webkitvisibilitychange';
    }

    console.log('Hidden flag = ' + this.hidden);
    console.log('Visibility changed event name = ' + this.visibilityChange);
  }

  startListening(): void {
    console.log('Listenig for page lifecycle changes');

    if (
      typeof document.addEventListener === 'undefined' ||
      this.hidden === undefined
    ) {
      console.log('Page Visibility API not found');
    } else {
      // Handle page visibility change
      document.addEventListener(
        this.visibilityChange,
        () => {
          if (document[this.hidden]) {
            this.pausedTime = new Date();
          } else if (this.pausedTime) {
            const sleepTime = new Date().getTime() - this.pausedTime.getTime();
            console.log('Sleep time: ' + sleepTime + 'ms');

            if (sleepTime >= this.maxSleepTimeUntilRefresh) {
              // refresh the whole page
              window.location.href = window?.location?.href;
            }
          }
        },
        false
      );
    }
  }
}
