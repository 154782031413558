import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { Component, ViewChild } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @ViewChild('consent')
  cookieConsentComponent: CookieConsentComponent;

  currentYear = new Date().getFullYear();

  constructor() {
    // remove page loading spinner and show content
    document.getElementById('initial-outer-loader')?.remove();
    document.getElementsByTagName('app-root')[0].removeAttribute('style');
  }

  openCookieConsentDialog(): void {
    this.cookieConsentComponent?.openDialog();
  }
}
