import { Subscription } from 'src/app/model/subscription/subscription';
import { SearchResultWrapper } from './../../model/search-result-wrapper/search-result-wrapper';
import { CachingService } from './../../services/caching/caching.service';
import { environment } from 'src/environments/environment';
import { contactFormUrl } from './../../services/contact/contact.service';
import {
  searchUrlGeneral,
  searchUrlMyPosts,
  searchUrlDetails,
} from './../../services/search/search.service';
import { SubscriptionType } from './../../model/subscription/subscription-type.enum';
import {
  loginUrl,
  saveUserUrl,
  resetPasswordUrl,
  requestResetPasswordUrl,
  registerUrl,
  validateEmailUrl,
} from './../../services/user/user.service';
import {
  addSubscriptionUrl,
  cancelSubscriptionUrl,
  getSubscriptionUrl,
  getSubscriptionTypesUrl,
} from './../../services/subscription/subscription.service';
import { Company } from 'src/app/model/company/company';
import { LoremIpsumService } from './../../services/lorem-ipsum/lorem-ipsum.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from 'src/app/model/user/user';
import { getCategoriesUrl } from 'src/app/services/category/category.service';
import {
  addOfferOrRequestUrl,
  deleteOfferOrRequestUrl,
} from 'src/app/services/offer-and-request/offer-and-request.service';
import { Request, Type } from 'src/app/model/request/request';
import { delay } from 'rxjs/operators';
import { validateAddressUrl } from 'src/app/services/address/address.service';
import { fetchPaymentConfigurationUrl } from 'src/app/services/payment/payment.service';

@Injectable()
export class HttpMockRequestInterceptor implements HttpInterceptor {
  constructor(
    private loremIpsumService: LoremIpsumService,
    private cachingService: CachingService
  ) {
    console.log(
      'Starting HTTP interceptor in ' +
        (environment.offlineMode ? 'offline' : 'online') +
        ' mode'
    );
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (environment.offlineMode && this.checkRequet(request.clone())) {
      console.log('Handling request with mock response: %s', request.url);
      const response = this.checkRequet(request);
      console.log('Sending %s back to client', response.body);
      return of(response).pipe(delay(1000 * Math.random()));
    }

    console.log('Loaded from http call :' + request.url);
    // only add token when calling our own systems
    if (request.url.indexOf(environment.restApiBase) >= 0) {
      request = request.clone({
        withCredentials: true,
      });
    }
    console.log(request);
    return next.handle(request);
  }

  /**
   * Checks if the given request can be handled by this Mock Handler
   *
   * @param request the request to check
   */
  checkRequet(request: HttpRequest<any>): HttpResponse<string> {
    if (request.url === loginUrl) {
      return this.createUserResponse(request);
    } else if (request.url === registerUrl) {
      return this.createUserResponse(request, true);
    } else if (request.url === validateEmailUrl) {
      return this.genericOk();
    } else if (request.url === saveUserUrl) {
      return this.createUserResponse(request, true);
    } else if (request.url === resetPasswordUrl) {
      return this.genericOk();
    } else if (request.url === requestResetPasswordUrl) {
      return this.genericOk();
    } else if (request.url === getCategoriesUrl) {
      return this.createCategoryTree();
    } else if (request.url === contactFormUrl) {
      return this.genericOk(request.body);
    } else if (request.url === getSubscriptionUrl) {
      return this.loadSubscription();
    } else if (request.url === addSubscriptionUrl) {
      return this.createNewSubscription(request);
    } else if (request.url === getSubscriptionTypesUrl) {
      return this.handleSubscriptionTypes();
    } else if (request.url === cancelSubscriptionUrl) {
      return this.createNewSubscription(request, true);
    } else if (request.url === addOfferOrRequestUrl) {
      return this.handleOfferOrRequest(request);
    } else if (request.url === deleteOfferOrRequestUrl) {
      return this.genericOk();
    } else if (request.url.startsWith(searchUrlDetails)) {
      return this.handleSearchDetails();
    } else if (request.url.startsWith(searchUrlGeneral)) {
      return this.handleSearch();
    } else if (request.url.startsWith(searchUrlMyPosts)) {
      return this.handleSearch();
    } else if (request.url.indexOf(validateAddressUrl) >= 0) {
      return this.genericOk('{"latitude":51.55237,"longitude":8.10613}');
    } else if (request.url.indexOf(fetchPaymentConfigurationUrl) >= 0) {
      return this.genericOk({
        paypal: 'paypal',
        klarna: 'klarna',
      });
    }

    console.log('No mock http handler found!');
    return null;
  }

  private genericOk(bodyContent?: any): HttpResponse<string> {
    return new HttpResponse({
      status: 200,
      body: bodyContent ? bodyContent : 'Generic ok',
    });
  }

  private createUserResponse(
    httpRequest: HttpRequest<any>,
    useRequestData: boolean = false
  ): HttpResponse<string> {
    let user: any = new User();

    if (!useRequestData) {
      user.firstName = this.loremIpsumService.getLorem().generateWords(1);
      user.lastName = this.loremIpsumService.getLorem().generateWords(1);
      user.email = httpRequest.body.username;

      user.firstName =
        user.firstName[0].toUpperCase() + (user.firstName as string).substr(1);
      user.lastName =
        user.lastName[0].toUpperCase() + (user.lastName as string).substr(1);

      user.company = Company.createDemoInstance(this.loremIpsumService);
    } else {
      user = httpRequest.body;
    }

    return new HttpResponse({ status: 200, body: user });
  }

  private loadSubscription(): HttpResponse<any> {
    const sub = new Subscription(
      new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 10),
      new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20),
      SubscriptionType.trial,
      false,
      null
    );

    return new HttpResponse({
      status: 200,
      body: sub,
    });
  }

  private createNewSubscription(
    httpRequest: HttpRequest<any>,
    canceled: boolean = false
  ): HttpResponse<any> {
    const json = {
      start: new Date().getTime() - 1000 * 60 * 60 * 24 * 10,
      end: new Date().getTime() + 1000 * 60 * 60 * 24 * 20,
      subscriptionType: httpRequest.body.type,
      canceled,
    };

    return new HttpResponse({
      status: 200,
      body: json,
    });
  }

  private handleOfferOrRequest(
    httpRequest: HttpRequest<any>
  ): HttpResponse<string> {
    const result = httpRequest.body;
    result.id = 'b' + Math.round(Math.random() * 100000);

    return new HttpResponse({
      status: 200,
      body: result,
    });
  }

  private handleSubscriptionTypes(): HttpResponse<any> {
    return new HttpResponse({
      status: 200,
      body: JSON.parse(
        '["none","yearly_recurring","monthly","weekly","trial"]'
      ),
    });
  }

  private handleSearch(): HttpResponse<any> {
    const types: Request[] = [];

    for (let i = 0; i < Math.random() * 20 + 1; i++) {
      const request = this.createSearchResult();
      types.push(request);
    }

    const response = new SearchResultWrapper();
    response.page = 0;
    response.requests = types;
    response.totalHits = types.length;
    response.totalPages = 3;

    return new HttpResponse({
      status: 200,
      body: response,
    });
  }

  private handleSearchDetails(): HttpResponse<any> {
    return new HttpResponse({
      status: 200,
      body: this.createSearchResult(),
    });
  }

  private createSearchResult(): Request {
    const request = new Request(Type.Offer);
    request.details =
      'echt ' +
      this.loremIpsumService
        .getLorem()
        .generateWords(Math.round(Math.random() * 50));
    request.headline = this.loremIpsumService
      .getLorem()
      .generateWords(Math.round(Math.random() * 4));
    request.price = Math.round(Math.random() * 10000);
    request.priceUnit = 'fixed_price';
    request.searchRadius = 50;
    request.creationDate = new Date();
    request.categoryId = 12;
    request.street = this.loremIpsumService
      .getLorem()
      .generateWords(Math.round(Math.random() * 2));
    request.city = this.loremIpsumService.getLorem().generateWords(1);
    request.zip = '59494';
    request.id = 'a' + Math.round(Math.random() * 1000);
    request.image =
      'https://picsum.photos/id/' +
      Math.round(Math.random() * 100) +
      '/900/500?';
    request.fromDate = new Date(
      new Date().getTime() + Math.round(Math.random() * 1000 * 60 * 60 * 24 * 3)
    );
    request.toDate = new Date(
      request.fromDate.getTime() +
        Math.round(Math.random() * 1000 * 60 * 60 * 24 * 10)
    );

    request.user = User.createDemoInstance(this.loremIpsumService);

    return request;
  }

  private getRandomInt(min, max): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  private createCategoryTree(): HttpResponse<string> {
    const json = JSON.stringify({
      name: 'root',
      description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
      imageUrl: `https://picsum.photos/id/${this.getRandomInt(
        1,
        200
      )}/900/500?`,
      id: 0,
      categories: [
        {
          name: 'Agrar',
          description:
            'Lorem, ipsum dolor, sit amet consectetur adipisicing elit.',
          imageUrl: `https://picsum.photos/id/${this.getRandomInt(
            1,
            200
          )}/900/500?`,
          id: 1,
          categories: [
            {
              name: 'Grünland',
              description:
                'Lorem. ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 5,
              categories: [
                {
                  name: 'Nachsaat',
                  description:
                    'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                  imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                    1,
                    200
                  )}/900/500?`,
                  id: 11,
                },
                {
                  name: 'Wiesen schleppen',
                  description:
                    'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                  imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                    1,
                    200
                  )}/900/500?`,
                  id: 12,
                },
                {
                  name: 'Düngung',
                  description:
                    'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                  imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                    1,
                    200
                  )}/900/500?`,
                  id: 13,
                },
              ],
            },
            {
              name: 'Ackerbau',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 6,
              categories: [
                {
                  name: 'Bodenbearbeitung',
                  description:
                    'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                  imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                    1,
                    200
                  )}/900/500?`,
                  id: 21,
                },
                {
                  name: 'Düngen / AHL',
                  description:
                    'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                  imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                    1,
                    200
                  )}/900/500?`,
                  id: 22,
                },
              ],
            },
            {
              name: 'Viehwirtschaft',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 7,
              categories: [
                {
                  name: 'Gülle mixen',
                  description:
                    'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                  imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                    1,
                    200
                  )}/900/500?`,
                  id: 23,
                },
              ],
            },
            {
              name: 'Biogas',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 8,
              categories: [
                {
                  name: 'Maissaat',
                  description:
                    'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                  imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                    1,
                    200
                  )}/900/500?`,
                  id: 24,
                },
                {
                  name: 'Häckseln',
                  description:
                    'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                  imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                    1,
                    200
                  )}/900/500?`,
                  id: 25,
                },
              ],
            },
            {
              name: 'Gemüse- und Obstanbau',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 9,
              categories: [
                {
                  name: 'Wein',
                  description:
                    'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                  imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                    1,
                    200
                  )}/900/500?`,
                  id: 26,
                },
                {
                  name: 'Bäume',
                  description:
                    'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                  imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                    1,
                    200
                  )}/900/500?`,
                  id: 27,
                },
                {
                  name: 'Spargel',
                  description:
                    'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                  imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                    1,
                    200
                  )}/900/500?`,
                  id: 28,
                },
                {
                  name: 'Erdbeeren / Himbeeren',
                  description:
                    'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                  imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                    1,
                    200
                  )}/900/500?`,
                  id: 29,
                },
                {
                  name: 'Gemüse',
                  description:
                    'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                  imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                    1,
                    200
                  )}/900/500?`,
                  id: 30,
                },
              ],
            },
            {
              name: 'Börse',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 10,
              categories: [
                {
                  name: 'Getreide (< 100t)',
                  description:
                    'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                  imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                    1,
                    200
                  )}/900/500?`,
                  id: 31,
                },
                {
                  name: 'Silage',
                  description:
                    'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                  imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                    1,
                    200
                  )}/900/500?`,
                  id: 32,
                },
              ],
            },
          ],
        },
        {
          name: 'Forst',
          description:
            'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
          imageUrl: `https://picsum.photos/id/${this.getRandomInt(
            1,
            200
          )}/900/500?`,
          id: 2,
          categories: [
            {
              name: 'Baumfällung',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 33,
            },
            {
              name: 'Brennholz',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 34,
            },
            {
              name: 'Aufforstung',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 35,
            },
            {
              name: 'Pflege',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 36,
            },
            {
              name: 'Harvester',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 37,
            },
            {
              name: 'Abfuhr',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 38,
            },
            {
              name: 'NaWaRo',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 39,
            },
          ],
        },
        {
          name: 'Garten und Landschaft',
          description:
            'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
          imageUrl: `https://picsum.photos/id/${this.getRandomInt(
            1,
            200
          )}/900/500?`,
          id: 3,
          categories: [
            {
              name: 'Gartenbau',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 40,
            },
            {
              name: 'Pflasterarbeiten',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 41,
            },
            {
              name: 'Rasen / Heckenschnitt',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 42,
            },
            {
              name: 'Baumschnitt',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 43,
            },
            {
              name: 'Teich / Springbrunnen',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 44,
            },
            {
              name: 'Bewirtschaftung',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 45,
            },
          ],
        },
        {
          name: 'Kommunal / Bau',
          description:
            'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
          imageUrl: `https://picsum.photos/id/${this.getRandomInt(
            1,
            200
          )}/900/500?`,
          id: 4,
          categories: [
            {
              name: 'Grünflächenpflege',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 46,
            },
            {
              name: 'Winterdienst',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 47,
            },
            {
              name: 'Baumschnitt',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 48,
            },
            {
              name: 'Baggerarbeiten',
              description:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
              imageUrl: `https://picsum.photos/id/${this.getRandomInt(
                1,
                200
              )}/900/500?`,
              id: 49,
            },
          ],
        },
      ],
    });
    return new HttpResponse({ status: 200, body: JSON.parse(json) });
  }
}
