<div
  [class.container]="useContainerDiv"
  [class.pt-md-5]="usePadding"
  [class.pt-4]="usePadding"
  [class.pb-md-4]="usePadding"
  class="outer"
>
  <div class="row">
    <div
      *ngIf="showRequest"
      [class.animate__animated]="useAnimations"
      [class.animate__fadeIn]="useAnimations"
      class="col-md-6 category-selection"
    >
      <div class="row no-gutters border rounded overflow-hidden mb-4 shadow-sm">
        <div
          routerLink="/pages/find/service/request"
          class="col p-4 d-flex flex-column position-static cursor-pointer"
        >
          <div class="d-flex">
            <svg
              class="mt-2 me-2 lens"
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                clip-rule="evenodd"
              />
              <path
                fill-rule="evenodd"
                d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                clip-rule="evenodd"
              />
            </svg>
            <h3 class="mt-auto">Ich suche</h3>
          </div>
          <div>
            <p class="card-text mb-auto">
              Hier können Sie Anzeigen/Gesuche erstellen, die von anderen
              gefunden werden können.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="showOffer"
      [class.animate__animated]="useAnimations"
      [class.animate__fadeIn]="useAnimations"
      class="col-md-6 category-selection"
    >
      <div class="row no-gutters border rounded overflow-hidden mb-4 shadow-sm">
        <div
          routerLink="/pages/find/service/offer"
          class="col p-4 d-flex flex-column position-static cursor-pointer"
        >
          <div class="d-flex">
            <svg
              class="mt-2 me-2 gear"
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 014.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 01-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 011.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 012.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 012.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 011.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 01-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 018.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 001.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 00.52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 00-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 00-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 00-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 00-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 00.52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 001.255-.52l.094-.319z"
                clip-rule="evenodd"
              />
              <path
                fill-rule="evenodd"
                d="M8 5.754a2.246 2.246 0 100 4.492 2.246 2.246 0 000-4.492zM4.754 8a3.246 3.246 0 116.492 0 3.246 3.246 0 01-6.492 0z"
                clip-rule="evenodd"
              />
            </svg>
            <h3 class="mt-auto">Ich biete</h3>
          </div>
          <div>
            <p class="card-text mb-auto">
              Sie können Ihre Dienste bereitstellen? Dann können Sie hier ihre
              Dienste anbieten oder schon offene Gesuche beantworten.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
