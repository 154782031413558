<ng-template #content let-modal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cookie-Banner</h4>
  </div>

  <ng-container>
    <div class="container">
      <ng-container>
        <p>
          Wir verwenden auf dieser Website Cookies. Diese dienen dazu, Inhalte
          und Anzeigen zu personalisieren. Zudem können wir dadurch die
          Funktionen sozialer Medien anbieten und Zugriffe auf unsere Website
          analysieren. Die Art Ihrer Verwendung unserer Website wird an unsere
          Partner für Werbung, soziale Medien und Analysen weitergegeben.
          Weitere Information, welche Cookies verwendet werden, finden Sie in
          unserer Datenschutzerklärung.
        </p>
        <p>
          <a
            href="/content/datasecurity?{{ hideConsentUrlParameter }}"
            target="_blank"
            title="Datenschutzhinweise"
          >
            Datenschutzerklärung
          </a>
        </p>
        <p>
          <a
            href="/content/imprint?{{ hideConsentUrlParameter }}"
            target="_blank"
            title="Impressum"
          >
            Impressum
          </a>
        </p>
      </ng-container>
      <ng-container *ngIf="showSettings">
        <hr />
        <div class="d-flex justify-content-between">
          <div>
            <label class="w-100" for="cookie-required">
              Notwendig<br />
              <small>
                Diese Cookies sind für den Betrieb der Seite essentiell und
                können daher nicht deaktiviert werden
              </small>
            </label>
          </div>
          <input
            disabled
            id="cookie-required"
            type="checkbox"
            value="required"
            checked="checked"
          />
        </div>
        <hr />
        <div class="d-flex justify-content-between">
          <div>
            <label class="w-100" for="cookie-comfort">
              Komfort<br />
              <small>
                Diese Cookies ermöglichen eine verbessertes Nutzungserlebnis
                unserer Seite. Sollten diese Cookies nicht erlaubt werden, dann
                können einige Dienste möglicherweise nicht im vollen Umfang
                funktionieren
              </small>
            </label>
          </div>
          <input
            id="cookie-comfort"
            [(ngModel)]="comfortCookies"
            type="checkbox"
            value="comfort"
          />
        </div>
        <hr />
        <div class="d-flex justify-content-between">
          <div>
            <label class="w-100" for="cookie-marketing">
              Marketing<br />
              <small>
                Wir verwenden Marketing Cookies, um unsere Seite besser
                analysieren zu können mit dem Ziel unseren Service stetig zu
                verbessern. Dabei werden anonymisiert Daten zur Nutzung unserer
                Seite erhoben.
              </small>
            </label>
          </div>
          <input
            id="cookie-marketing"
            [(ngModel)]="marketingCookies"
            type="checkbox"
            value="marketing"
          />
        </div>
      </ng-container>
    </div>
    <div class="modal-footer justify-content-between">
      <button
        *ngIf="!showSettings"
        type="button"
        class="btn btn-primary"
        (click)="showSettings = true"
      >
        Einstellungen
      </button>
      <button
        *ngIf="showSettings"
        type="button"
        class="btn btn-primary"
        (click)="modal.close('selected')"
      >
        Auswahl übernehmen
      </button>
      <button
        type="button"
        ngbAutofocus
        class="btn btn-primary"
        (click)="modal.close('all')"
      >
        Alle zulassen
      </button>
    </div>
  </ng-container>
</ng-template>
