import { Company } from 'src/app/model/company/company';
import { LoremIpsumService } from 'src/app/services/lorem-ipsum/lorem-ipsum.service';
export class User {
  id: string;

  email: string;

  password: string;

  firstName: string;

  lastName: string;

  company: Company;

  get fullName() {
    return this.firstName + ' ' + this.lastName;
  }

  public static createDemoInstance(loremIpsumService: LoremIpsumService): User {
    const company = Company.createDemoInstance(loremIpsumService);
    const user = new User();
    user.firstName = loremIpsumService.getLorem().generateWords(1);
    user.lastName = loremIpsumService.getLorem().generateWords(1);
    user.email = user.firstName + '.' + user.lastName + '@company.de';
    user.company = company;

    return user;
  }

  clone(): User {
    const clone = Object.assign(new User(), this) as User; // deep copy
    clone.company = this.company.clone();

    return clone;
  }
}
