import { DomSanitizer } from '@angular/platform-browser';
import { EventEmitter, Output, OnInit } from '@angular/core';
/* eslint-disable @angular-eslint/no-input-rename */
import {
  Directive,
  AfterViewInit,
  HostBinding,
  Input,
  ElementRef,
} from '@angular/core';

@Directive({
  selector: '[lazyLoad]',
})
export class LazyImageLoadingDirective implements OnInit, AfterViewInit {
  @HostBinding('attr.srcset')
  srcsetAttr = null;

  @HostBinding('attr.src')
  srcAttr = null;

  @Input()
  srcset: string;

  @Input()
  src: string;

  @Input()
  placeholder: string;

  @Input('data-srcset')
  dataSrcset: string;

  @Input('data-src')
  dataSrc: string;

  @HostBinding('class')
  class: string;

  @Output()
  loaded = new EventEmitter<boolean>();

  constructor(private el: ElementRef, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.placeholder) {
      this.loadImage(this.sanitizer.bypassSecurityTrustUrl(this.placeholder));
    }
  }

  ngAfterViewInit() {
    if (this.canLazyLoad()) {
      this.lazyLoadImage();
    } else {
      this.loadImage();
    }
  }

  private canLazyLoad() {
    return window && 'IntersectionObserver' in window;
  }

  private lazyLoadImage() {
    const obs = new IntersectionObserver((entries) => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this.loadImage();
          obs.unobserve(this.el.nativeElement);
        }
      });
    });
    obs.observe(this.el.nativeElement);
  }

  private loadImage(image = null) {
    if (this.dataSrcset) {
      this.srcsetAttr = image ? image : this.srcset;
    } else if (this.dataSrc) {
      this.srcAttr = image ? image : this.dataSrc;
    } else if (this.src) {
      this.srcAttr = image ? image : this.src;
    } else if (this.srcset) {
      this.srcsetAttr = image ? image : this.srcset;
    } else {
      this.class = 'show-background-image';
    }
    this.loaded.emit(true);
  }
}
