import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private routerSubscription: Subscription;

  private running = false;

  constructor(private meta: Meta, private router: Router) {}

  public init(): void {
    this.running = true;

    // Change page title on navigation based on route data
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => {
        this.updateRobots('INDEX, FOLLOW');
        this.updateDescription(
          'Ihr Portal um Aufträge oder Gesuche rund um die Landwirtschaft zu verwalten.'
        );
        this.updateKeywords(
          'Landwirtschaft, Kommual, Garten und Landschaftsbau, Agrar'
        );
        this.updateAuthor('Farming365');
      });
  }

  public destroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription?.unsubscribe();
    }
    this.running = false;
  }

  public excludeCurrentPageFromIndexing(): void {
    this.updateRobots('noindex');
  }

  public updateDescription(text: string): void {
    this.updateMetaTag('Description', text);
  }

  public updateKeywords(text: string): void {
    this.updateMetaTag('keywords', text);
  }

  public updateAuthor(text: string): void {
    this.updateMetaTag('author', text);
  }

  private updateRobots(text: string): void {
    this.updateMetaTag('robots', text);
  }

  private updateMetaTag(tag: string, text: string): void {
    if (this.running) {
      this.meta.updateTag({ name: tag, content: text });
    }
  }
}
