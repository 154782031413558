import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

/**
 *
 *
 * Use .\src\image-util\jimp.js to generate images
 *
 *
 */

export let webpAllowed = null;
export let avifAllowed = null;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'picture[app-image-renderer]',
  templateUrl: './image-renderer.component.html',
  styleUrls: ['./image-renderer.component.scss'],
})
export class ImageRendererComponent implements OnInit {
  @Input()
  imgSrc: string;

  @Input()
  imgClass: string;

  @Input()
  alt: string;

  @Input()
  title: string;

  @Input()
  useLazyLoading = false;

  @Output()
  loaded = new EventEmitter<boolean>();

  @Input()
  fallbackExtension;

  readonly fallbackExtension2 = 'jpg';

  isWebP = false;

  isAvif = false;

  isJpg = false;

  extension = null;

  resolutionImgSrcs = [];

  resolutionImgValues = [];

  readonly extraSmall = 'xs';
  readonly small = 'sm';
  readonly medium = 'md';
  readonly large = 'lg';
  readonly extraLarge = 'xl';
  readonly extraExtraLarge = 'xxl';
  readonly extraExtraExtraLarge = 'xxxl';
  readonly extraExtraExtraExtraLarge = 'xxxxl';

  resolutions = [
    this.extraSmall,
    this.small,
    this.medium,
    this.large,
    this.extraLarge,
    this.extraExtraLarge,
    this.extraExtraExtraLarge,
    this.extraExtraExtraExtraLarge,
  ];

  constructor() {
    this.resolutionImgValues[this.extraSmall] = '(max-width:575.98px)';
    this.resolutionImgValues[this.small] = '(max-width:767.98px)';
    this.resolutionImgValues[this.medium] = '(max-width:991.98px)';
    this.resolutionImgValues[this.large] = '(max-width:1199.98px)';
    this.resolutionImgValues[this.extraLarge] = '(max-width:1399.98px)';
    this.resolutionImgValues[this.extraExtraLarge] = '(max-width:1599.98px)';
    this.resolutionImgValues[this.extraExtraExtraLarge] =
      '(max-width:1799.98px)';
    this.resolutionImgValues[this.extraExtraExtraExtraLarge] =
      '(min-width:1800px)';
  }

  ngOnInit(): void {
    this.imgSrc =
      this.imgSrc.slice(this.imgSrc.lastIndexOf('/')).indexOf('.') > 0
        ? this.imgSrc
        : this.imgSrc + '.' + this.fallbackExtension2; // fallback added

    this.handleImage(this.imgSrc);
  }

  private handleImage(imageUrl: string): void {
    this.isWebP = imageUrl.toLowerCase().endsWith('.webp');
    this.isAvif = imageUrl.toLowerCase().endsWith('.avif');
    this.isJpg = imageUrl.toLowerCase().endsWith('.jpg');

    if (this.isAvif) {
      this.tryToUseAvif(imageUrl);
    } else if (this.isWebP) {
      this.tryToUseWebP(imageUrl);
    } else if (this.isJpg) {
      this.prepareImages(this.fallbackExtension2);
    } else {
      this.prepareImages(imageUrl.slice(imageUrl.lastIndexOf('.') + 1));
    }
  }

  private tryToUseAvif(imageUrl: string): void {
    this.checkAvifFeature().then((result) => {
      if (result) {
        this.prepareImages('avif'); //avif is supported.
      } else if (this.fallbackExtension) {
        this.handleImage(
          imageUrl.slice(0, imageUrl.lastIndexOf('.')) +
            '.' +
            this.fallbackExtension
        );
      } else {
        this.prepareImages(this.fallbackExtension2);
      }
    });
  }

  private tryToUseWebP(imageUrl: string): void {
    this.checkWebpFeature().then((result) => {
      if (result) {
        this.prepareImages('webp'); //webp is supported.
      } else if (this.fallbackExtension) {
        this.handleImage(
          imageUrl.slice(0, imageUrl.lastIndexOf('.')) +
            '.' +
            this.fallbackExtension
        );
      } else {
        this.prepareImages(this.fallbackExtension2);
      }
    });
  }

  private prepareImages(extension: string): void {
    const imgPart = this.imgSrc.slice(0, this.imgSrc.lastIndexOf('.'));

    this.resolutions.forEach((res) => {
      const img = imgPart + '-' + res + '.' + extension;
      this.resolutionImgSrcs[res] = img;
    });

    this.extension = extension;
  }

  /**
   * Checks if the browser supports the WebP feature
   */
  private async checkWebpFeature(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (webpAllowed === false) {
        resolve(false);
      } else if (webpAllowed === true) {
        resolve(true);
      }

      const img = new Image();
      img.onload = () => {
        const result = img.width > 0 && img.height > 0;
        webpAllowed = result;
        resolve(result);
      };
      img.onerror = () => {
        webpAllowed = false;
        resolve(false);
      };
      img.src =
        // eslint-disable-next-line max-len
        'data:image/webp;base64,UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA';
    });
  }

  /**
   * Checks if the browser supports the WebP feature
   */
  private async checkAvifFeature(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (avifAllowed === false) {
        resolve(false);
      } else if (avifAllowed === true) {
        resolve(true);
      }

      const img = new Image();
      img.onload = () => {
        const result = img.width > 0 && img.height > 0;
        avifAllowed = result;
        resolve(result);
      };
      img.onerror = () => {
        avifAllowed = false;
        resolve(false);
      };
      img.src =
        // eslint-disable-next-line max-len
        'data:image/avif;base64,AAAAFGZ0eXBhdmlmAAAAAG1pZjEAAACgbWV0YQAAAAAAAAAOcGl0bQAAAAAAAQAAAB5pbG9jAAAAAEQAAAEAAQAAAAEAAAC8AAAAGwAAACNpaW5mAAAAAAABAAAAFWluZmUCAAAAAAEAAGF2MDEAAAAARWlwcnAAAAAoaXBjbwAAABRpc3BlAAAAAAAAAAQAAAAEAAAADGF2MUOBAAAAAAAAFWlwbWEAAAAAAAAAAQABAgECAAAAI21kYXQSAAoIP8R8hAQ0BUAyDWeeUy0JG+QAACANEkA=';
    });
  }
}
