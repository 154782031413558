import { RouterModule } from '@angular/router';
/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from '../util/safe-html/safe-html.pipe';
import { LinkRendererPipe } from '../util/link-renderer/link-renderer.pipe';
import { LazyImageLoadingDirective } from '../util/lazy-image-loading/lazy-image-loading.directive';
import { RunScriptDirective } from '../util/run-script/run-script.directive';
import { SpinnerComponent } from '../animations/spinner/spinner.component';
import { PopoverComponent } from '../animations/popover/popover.component';
import { SpinnerButtonComponent } from '../animations/spinner-button/spinner-button.component';
import { TextOnImageComponent } from '../animations/text-on-image/text-on-image.component';
import { ToastComponent } from '../animations/toast/toast.component';
import { CookieConsentComponent } from '../page-parts/footer/cookie-consent/cookie-consent.component';
import { FooterComponent } from '../page-parts/footer/footer.component';
import { HeaderComponent } from '../page-parts/header/header.component';
import { NavigationBarComponent } from '../page-parts/header/navigation-bar/navigation-bar.component';
import { CategoryBreadcrumbComponent } from '../page-parts/main-content/category-breadcrumb/category-breadcrumb.component';
import { CategorySearchComponent } from '../page-parts/main-content/category-search/category-search.component';
import { CompanyAboutComponent } from '../page-parts/main-content/company-views/company-head-view/company-about/company-about.component';
import { CompanyHeadViewComponent } from '../page-parts/main-content/company-views/company-head-view/company-head-view.component';
import { CompanyImageComponent } from '../page-parts/main-content/company-views/company-head-view/company-image/company-image.component';
import { CompanyTableViewComponent } from '../page-parts/main-content/company-views/company-table-view/company-table-view.component';
import { DeactivateAccountComponent } from '../page-parts/main-content/deactivate-account/deactivate-account.component';
import { EditProfileComponent } from '../page-parts/main-content/edit-profile/edit-profile.component';
import { FindOfferComponent } from '../page-parts/main-content/find-offer/find-offer.component';
import { MainContentComponent } from '../page-parts/main-content/main-content.component';
import { MapsComponent } from '../page-parts/main-content/maps/maps.component';
import { NotificationSettingsComponent } from '../page-parts/main-content/notification-settings/notification-settings.component';
import { PaginationComponent } from '../page-parts/main-content/pagination/pagination.component';
import { PicturesComponent } from '../page-parts/main-content/pictures/pictures.component';
import { ResetPasswordLinkComponent } from '../page-parts/main-content/reset-password-link/reset-password-link.component';
import { SearchResultDetailComponent } from '../page-parts/main-content/search-result-detail/search-result-detail.component';
import { SearchResultSortingComponent } from '../page-parts/main-content/search-result-sorting/search-result-sorting.component';
import { StageTeaserComponent } from '../page-parts/main-content/stage-teaser/stage-teaser.component';
import { CancelSubscriptionComponent } from '../page-parts/main-content/subscriptions/cancel-subscription/cancel-subscription.component';
import { ManageSubscriptionComponent } from '../page-parts/main-content/subscriptions/manage-subscription/manage-subscription.component';
import { SubscriptionComponent } from '../page-parts/main-content/subscriptions/subscription/subscription.component';
import { TextPictureComponent } from '../page-parts/main-content/text-picture/text-picture.component';
import { DateFormComponent } from '../page-parts/utils/date-form/date-form.component';
import { ConfirmationDialogComponent } from '../page-parts/utils/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AddressExtrasFormComponent } from '../page-parts/utils/dummy-forms/address-extras-form/address-extras-form.component';
import { CityFormComponent } from '../page-parts/utils/dummy-forms/city-form/city-form.component';
import { CompanyAboutFormComponent } from '../page-parts/utils/dummy-forms/company-about-form/company-about-form.component';
import { ComboboxFormComponent } from '../page-parts/utils/dummy-forms/combobox-form/combobox-form.component';
import { CompanyNameFormComponent } from '../page-parts/utils/dummy-forms/company-name-form/company-name-form.component';
import { EmailFormComponent } from '../page-parts/utils/dummy-forms/email-form/email-form.component';
import { FaxFormComponent } from '../page-parts/utils/dummy-forms/fax-form/fax-form.component';
import { FirstnameFormComponent } from '../page-parts/utils/dummy-forms/firstname-form/firstname-form.component';
import { ImageUploadFormComponent } from '../page-parts/utils/dummy-forms/image-upload-form/image-upload-form.component';
import { LastnameFormComponent } from '../page-parts/utils/dummy-forms/lastname-form/lastname-form.component';
import { MobileFormComponent } from '../page-parts/utils/dummy-forms/mobile-form/mobile-form.component';
import { PasswordRepeatFormComponent } from '../page-parts/utils/dummy-forms/password-repeat-form/password-repeat-form.component';
import { PriceUnitFormComponent } from '../page-parts/utils/dummy-forms/price-unit-form/price-unit-form.component';
import { SearchRadiusFormComponent } from '../page-parts/utils/dummy-forms/search-radius-form/search-radius-form.component';
import { SimplePasswordFormComponent } from '../page-parts/utils/dummy-forms/simple-password-form/simple-password-form.component';
import { StreetFormComponent } from '../page-parts/utils/dummy-forms/street-form/street-form.component';
import { TelephoneFormComponent } from '../page-parts/utils/dummy-forms/telephone-form/telephone-form.component';
import { WebsiteFormComponent } from '../page-parts/utils/dummy-forms/website-form/website-form.component';
import { ZipFormComponent } from '../page-parts/utils/dummy-forms/zip-form/zip-form.component';
import { SimpleFormSelectorComponent } from '../page-parts/utils/simple-form-selector/simple-form-selector.component';
import { SimpleFormComponent } from '../page-parts/utils/simple-form/simple-form.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NavigationProfileWidgetComponent } from '../page-parts/header/navigation-bar/navigation-profile-widget/navigation-profile-widget.component';
import { CompanyHeadlineComponent } from '../page-parts/main-content/company-views/company-head-view/company-headline/company-headline.component';
import { CompanyTableViewRowComponent } from '../page-parts/main-content/company-views/company-table-view/company-table-view-row/company-table-view-row.component';
import { SubscriptionSelectionComponent } from '../page-parts/main-content/subscriptions/subscription-selection/subscription-selection.component';
import { ImageRendererComponent } from '../util/image-renderer/image-renderer.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { DatasecurityAndAgbComponent } from '../page-parts/utils/dummy-forms/datasecurity-and-agb/datasecurity-and-agb.component';
import { CopyButtonComponent } from '../page-parts/utils/buttons/copy-button/copy-button.component';
import { WatchlistButtonComponent } from '../page-parts/utils/buttons/watchlist-button/watchlist-button.component';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    ImageRendererComponent,
    LinkRendererPipe,
    LazyImageLoadingDirective,
    RunScriptDirective,
    SpinnerComponent,
    CategoryBreadcrumbComponent,
    SimpleFormComponent,
    SearchResultDetailComponent,
    DateFormComponent,
    SubscriptionComponent,
    PopoverComponent,
    HeaderComponent,
    ToastComponent,
    MapsComponent,
    CopyButtonComponent,
    WatchlistButtonComponent,
    TextOnImageComponent,
    FooterComponent,
    MainContentComponent,
    FindOfferComponent,
    PicturesComponent,
    NavigationBarComponent,
    CompanyTableViewComponent,
    CompanyTableViewRowComponent,
    SimpleFormSelectorComponent,
    FaxFormComponent,
    StreetFormComponent,
    TelephoneFormComponent,
    MobileFormComponent,
    CityFormComponent,
    ZipFormComponent,
    ComboboxFormComponent,
    FirstnameFormComponent,
    LastnameFormComponent,
    EmailFormComponent,
    CompanyNameFormComponent,
    AddressExtrasFormComponent,
    SimplePasswordFormComponent,
    PasswordRepeatFormComponent,
    WebsiteFormComponent,
    DatasecurityAndAgbComponent,
    CompanyHeadViewComponent,
    CompanyImageComponent,
    CompanyHeadlineComponent,
    CompanyAboutComponent,
    CompanyAboutFormComponent,
    NavigationProfileWidgetComponent,
    EditProfileComponent,
    ResetPasswordLinkComponent,
    ManageSubscriptionComponent,
    SubscriptionSelectionComponent,
    StageTeaserComponent,
    CancelSubscriptionComponent,
    SpinnerButtonComponent,
    TextPictureComponent,
    PaginationComponent,
    PriceUnitFormComponent,
    SearchRadiusFormComponent,
    SearchResultSortingComponent,
    CookieConsentComponent,
    NotificationSettingsComponent,
    DeactivateAccountComponent,
    ConfirmationDialogComponent,
    CategorySearchComponent,
    ImageUploadFormComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    GoogleMapsModule,
    ImageCropperModule,
    RouterModule,
  ],
  exports: [
    SafeHtmlPipe,
    LinkRendererPipe,
    LazyImageLoadingDirective,
    RunScriptDirective,
    SpinnerComponent,
    CategoryBreadcrumbComponent,
    SimpleFormComponent,
    SearchResultDetailComponent,
    DateFormComponent,
    SubscriptionComponent,
    PopoverComponent,
    HeaderComponent,
    ToastComponent,
    MapsComponent,
    TextOnImageComponent,
    FooterComponent,
    MainContentComponent,
    FindOfferComponent,
    PicturesComponent,
    NavigationBarComponent,
    CopyButtonComponent,
    WatchlistButtonComponent,
    CompanyTableViewComponent,
    CompanyTableViewRowComponent,
    SimpleFormSelectorComponent,
    FaxFormComponent,
    StreetFormComponent,
    TelephoneFormComponent,
    MobileFormComponent,
    CityFormComponent,
    ZipFormComponent,
    ComboboxFormComponent,
    FirstnameFormComponent,
    LastnameFormComponent,
    EmailFormComponent,
    DatasecurityAndAgbComponent,
    CompanyNameFormComponent,
    AddressExtrasFormComponent,
    SimplePasswordFormComponent,
    PasswordRepeatFormComponent,
    WebsiteFormComponent,
    CompanyHeadViewComponent,
    CompanyImageComponent,
    CompanyHeadlineComponent,
    CompanyAboutComponent,
    CompanyAboutFormComponent,
    NavigationProfileWidgetComponent,
    EditProfileComponent,
    ResetPasswordLinkComponent,
    ManageSubscriptionComponent,
    SubscriptionSelectionComponent,
    StageTeaserComponent,
    CancelSubscriptionComponent,
    SpinnerButtonComponent,
    TextPictureComponent,
    PaginationComponent,
    PriceUnitFormComponent,
    SearchRadiusFormComponent,
    SearchResultSortingComponent,
    CookieConsentComponent,
    NotificationSettingsComponent,
    DeactivateAccountComponent,
    ConfirmationDialogComponent,
    CategorySearchComponent,
    ImageUploadFormComponent,
  ],
})
export class SharedModule {}
