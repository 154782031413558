import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, EMPTY } from 'rxjs';
import { Injectable } from '@angular/core';
import { SubscriptionType } from 'src/app/model/subscription/subscription-type.enum';
import { Subscription } from 'src/app/model/subscription/subscription';
import { retry, map, catchError, take } from 'rxjs/operators';
import { StatusCodeHelper } from 'src/app/http/util/status-code/status-code-helper';
import { environment } from 'src/environments/environment';

export const addSubscriptionUrl =
  environment.restApiBase + '/v1/subscription/create';

export const cancelSubscriptionUrl =
  environment.restApiBase + '/v1/subscription/cancel';

export const getSubscriptionUrl =
  environment.restApiBase + '/v1/subscription/get';

export const getSubscriptionTypesUrl =
  environment.restApiBase + '/v1/subscription/types';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private currentSubscriptionSubject: BehaviorSubject<Subscription>;

  private availableSubscriptionTypesSubject: BehaviorSubject<
    SubscriptionType[]
  >;

  constructor(private http: HttpClient) {
    this.currentSubscriptionSubject = new BehaviorSubject<Subscription>(null);
    this.availableSubscriptionTypesSubject = new BehaviorSubject<
      SubscriptionType[]
    >(null);

    // initialize current subscription
    this.loadSubscription()
      .pipe(take(2))
      .subscribe(() => {
        console.log(
          'Initializing subscription:\n%s',
          JSON.stringify(this.subscription)
        );
      });

    this.loadAvailableSubscriptionTypes()
      .pipe(take(2))
      .subscribe((types) => {
        if (types) {
          console.log(
            'Initializing subscription types:\n%s',
            JSON.stringify(types)
          );
        }
      });
  }

  private get subscription(): Subscription {
    let subsciption = this.currentSubscriptionSubject.value;
    if (!subsciption) {
      subsciption = null;
    }
    return subsciption;
  }

  private set subscription(subscription: Subscription) {
    this.currentSubscriptionSubject.next(subscription);
  }

  getSubscriptionObservable(): Observable<Subscription> {
    return this.currentSubscriptionSubject.asObservable();
  }

  getSubscriptionTypes(): Observable<SubscriptionType[]> {
    return this.availableSubscriptionTypesSubject.asObservable();
  }

  isSubscriptionCancelled(): boolean {
    if (this.subscription && this.subscription.cancellationDate) {
      return true;
    }
    return false;
  }

  isFreeTrialUsed(): boolean {
    if (this.subscription && this.subscription.freeTierUsed) {
      return true;
    }
    return false;
  }

  isSubscriptionActive(): boolean {
    const now = new Date().getTime();
    let result = false;
    if (
      this.subscription &&
      this.subscription.activationDate &&
      this.subscription.deactivationDate
    ) {
      console.log('Checking if subscription %o is active', this.subscription);
      result =
        now >= this.subscription.activationDate.getTime() &&
        now <= this.subscription.deactivationDate.getTime();
    }
    result = result ? this.subscription.type !== SubscriptionType.none : false;
    console.log('Is subscription active? = %s', result);
    return result;
  }

  getSubscriptionExpiryDate(): Date {
    return this.subscription && this.subscription?.deactivationDate
      ? this.subscription.deactivationDate
      : null;
  }

  getSubscriptionCancellationDate(): Date {
    return this.subscription && this.subscription?.cancellationDate
      ? this.subscription.cancellationDate
      : null;
  }

  getSubscriptionType(): SubscriptionType {
    return this.subscription ? this.subscription.type : null;
  }

  activateSubscription(type: SubscriptionType): Promise<Subscription> {
    console.log('Activating new subscription: %s', type);
    return this.http
      .post<Subscription>(
        addSubscriptionUrl,
        {
          type,
        },
        { observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<Subscription>) =>
          this.mapToSubscriptionObject(response)
        ),
        catchError((err: HttpErrorResponse) => {
          console.log('Handling error for subscription response');
          console.error(err);
          return EMPTY;
        })
      )
      .toPromise();
  }

  cancelSubscription(): Promise<Subscription> {
    console.log('Canceling subscription: %s', this.subscription);
    return this.http
      .post<Subscription>(cancelSubscriptionUrl, null, {
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<Subscription>) =>
          this.mapToSubscriptionObject(response)
        ),
        catchError((err: HttpErrorResponse) => {
          console.log('Handling error for subscription cancelling response');
          console.error(err);
          return EMPTY;
        })
      )
      .toPromise();
  }

  loadSubscription(): Observable<Subscription> {
    console.log('Loading current subscription from server');
    this.http
      .get<Subscription>(getSubscriptionUrl, { observe: 'response' })
      .pipe(
        retry(1),
        map((response: HttpResponse<Subscription>) =>
          this.mapToSubscriptionObject(response)
        ),
        catchError((err: HttpErrorResponse) => {
          console.log('Handling error for subscription response');
          console.error(err);
          return EMPTY;
        })
      )
      .toPromise();

    return this.currentSubscriptionSubject.asObservable();
  }

  loadAvailableSubscriptionTypes(): Observable<SubscriptionType[]> {
    console.log('Loading available subscription types from server');
    this.http
      .get<SubscriptionType[]>(getSubscriptionTypesUrl, { observe: 'response' })
      .pipe(
        retry(1),
        map((response: HttpResponse<SubscriptionType[]>) =>
          this.availableSubscriptionTypesSubject.next(response.body)
        ),
        catchError((err: HttpErrorResponse) => {
          console.log('Handling error for subscription types response');
          console.error(err);
          return EMPTY;
        })
      )
      .toPromise();

    return this.availableSubscriptionTypesSubject.asObservable();
  }

  private mapToSubscriptionObject(
    response: HttpResponse<Subscription>
  ): Subscription {
    console.log('Checking subscription response for: %o', response.body);
    if (StatusCodeHelper.is2XX(response)) {
      const sub = Object.assign(new Subscription(), response.body);
      sub.activationDate = new Date(+sub.activationDate);
      sub.deactivationDate = new Date(+sub.deactivationDate);
      if (sub.cancellationDate) {
        sub.cancellationDate = new Date(+sub.cancellationDate);
      }

      this.subscription = sub;
    } else {
      this.subscription = null;
    }
    return this.subscription;
  }
}
