import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-stage-teaser',
  templateUrl: './stage-teaser.component.html',
  styleUrls: ['./stage-teaser.component.scss'],
})
export class StageTeaserComponent {
  mobileResolution: boolean;

  constructor() {
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1024) {
      this.mobileResolution = false;
    } else {
      this.mobileResolution = true;
    }
  }
}
