import { environment } from './../../../environments/environment';
import { Request } from './../../model/request/request';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { StatusCodeHelper } from 'src/app/http/util/status-code/status-code-helper';

export const addOfferOrRequestUrl =
  environment.restApiBase + '/v1/requests/add';
export const deleteOfferOrRequestUrl =
  environment.restApiBase + '/v1/requests/delete';

@Injectable({
  providedIn: 'root',
})
export class OfferAndRequestService {
  constructor(private http: HttpClient) {}

  async add(request: Request): Promise<Request> {
    console.log('Creating new %s', request.type);

    return this.http
      .post<string>(addOfferOrRequestUrl, request, { observe: 'response' })
      .pipe(
        map((response) => {
          console.log('Checking store response for: %s', response);

          let serverResult: Request = null;
          if (StatusCodeHelper.is2XX(response)) {
            serverResult = Object.assign(
              new Request(request.type),
              response.body
            );
            console.log(serverResult);
          }

          if (!serverResult) {
            console.error(response);
            throw new Error(
              'Server returned invalid result. Status code=' + response.status
            );
          }

          return serverResult;
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('Handling error for store response');
          console.error(err);
          throw new Error(
            'Server returned invalid result. Status code=' + err.status
          );
        })
      )
      .toPromise();
  }

  async delete(request: Request): Promise<boolean> {
    console.log('Deleting %s', request.id);

    return this.http
      .post<string>(deleteOfferOrRequestUrl, request, { observe: 'response' })
      .pipe(
        map((response) => {
          console.log('Checking delete response for: %s', response);

          if (StatusCodeHelper.is2XX(response)) {
            return true;
          }
          return false;
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('Handling error for delete response');
          console.error(err);
          throw new Error(
            'Server returned invalid result. Status code=' + err.status
          );
        })
      )
      .toPromise();
  }
}
