import { User } from './../user/user';
import { LoremIpsumService } from './../../services/lorem-ipsum/lorem-ipsum.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
export class Company {
  id: string;

  name: string;

  website: string;

  telephone: string;

  fax: string;

  mobilePhone: string;

  street: string;

  zip: string;

  city: string;

  aboutUs: string;

  logo: string;

  creationDate: Date = new Date();

  commercial: boolean;

  public static createDemoInstance(
    loremIpsumService: LoremIpsumService
  ): Company {
    const company = new Company();
    company.name =
      loremIpsumService.getLorem().generateWords(Math.random() > 0.5 ? 1 : 2) +
      ' GmbH';
    company.aboutUs = loremIpsumService.getLorem().generateWords(30);
    company.website = 'https://company.de';
    company.telephone = '02921 / ' + Math.round(Math.random() * 1000000);
    company.mobilePhone = '0160 ' + Math.round(Math.random() * 1000000);
    company.creationDate = new Date();
    company.fax = '02921 ' + Math.round(Math.random() * 1000000);
    company.street =
      loremIpsumService.getLorem().generateWords(1) +
      ' ' +
      Math.round(Math.random() * 10);
    company.zip = '' + Math.round(Math.random() * 100000);
    company.city = loremIpsumService.getLorem().generateWords(1);
    company.commercial = Math.random() > 0.5;
    company.logo =
      'https://picsum.photos/id/' +
      Math.round(Math.random() * 100) +
      '/900/500';

    return company;
  }

  clone(): Company {
    const clone = Object.assign(new Company(), this) as Company; // deep copy
    clone.creationDate = Object.assign(new Date(), this.creationDate);

    return clone;
  }
}
