import { StatusCodeHelper } from './../../http/util/status-code/status-code-helper';
import { Observable, concat, EMPTY, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Contact } from 'src/app/model/contact-form/contact';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { take, retry, map, catchError } from 'rxjs/operators';
import { loginUrl } from '../user/user.service';
import { environment } from 'src/environments/environment';
import { JsonPipe } from '@angular/common';

export const contactFormUrl =
  environment.restApiBase + '/v1/contact/contactform';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private http: HttpClient) {}

  sendNotification(contact: Contact): Observable<boolean> {
    console.log('Sending contact form for %s', JSON.stringify(concat));

    return this.http
      .post<Contact>(contactFormUrl, contact, { observe: 'response' })
      .pipe(
        take(1),
        map((response) => {
          console.log(
            'Checking contact form response for: %s',
            JSON.stringify(response)
          );

          if (StatusCodeHelper.is2XX(response)) {
            return true;
          } else {
            throw Error('Server error: HTTP=' + response.status);
          }
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('Handling error for contact form response');
          console.error(err);
          throw Error('Server error: ' + err);
        })
      );
  }
}
