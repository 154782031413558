import { SubscriptionType } from './subscription-type.enum';

export class Subscription {
  constructor(
    public activationDate: Date = null,
    public deactivationDate: Date = null,
    public type: SubscriptionType = SubscriptionType.none,
    public freeTierUsed: boolean = false,
    public cancellationDate: Date = null
  ) {}
}
