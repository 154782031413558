<ng-container *ngIf="extension">
  <ng-container *ngIf="!useLazyLoading">
    <source
      *ngFor="let res of resolutions"
      [media]="resolutionImgValues[res]"
      [srcset]="resolutionImgSrcs[res]"
      [type]="'image/' + extension"
    />

    <img
      [class]="imgClass ? imgClass : ''"
      [src]="resolutionImgSrcs[large]"
      [alt]="alt ? alt : ''"
      [title]="title ? title : ''"
    />
  </ng-container>

  <ng-container *ngIf="useLazyLoading">
    <source
      lazyLoad
      *ngFor="let res of resolutions"
      [media]="resolutionImgValues[res]"
      [srcset]="resolutionImgSrcs[res]"
      (loaded)="loaded.emit($event)"
      [type]="'image/' + extension"
    />

    <img
      lazyLoad
      [class]="imgClass ? imgClass : ''"
      [src]="resolutionImgSrcs[large]"
      [alt]="alt ? alt : ''"
      [title]="title ? title : ''"
      (loaded)="loaded.emit($event)"
    />
  </ng-container>
</ng-container>
