import { HttpResponse, HttpResponseBase } from '@angular/common/http';
export class StatusCodeHelper {
  public static is2XX(response: HttpResponse<any>): boolean {
    if (response) {
      if (response.status >= 200 && response.status < 300) {
        return true;
      }
    }
    return false;
  }

  public static is4XX(response: HttpResponseBase): boolean {
    if (response) {
      if (response.status >= 400 && response.status < 500) {
        return true;
      }
    }
    return false;
  }
}
