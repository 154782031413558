import { HostListener, Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

export abstract class ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

export const leavePageMessage =
  'Warnung: Sie haben noch nicht gespeicherte Inhalte.' +
  ' Klicken Sie auf "Abbrechen" um zurück zu gehen um die Bearbeitung abzuschließen. ' +
  'Wenn Sie dennoch die Seite verlassen möchten könen Sie einfach auf "OK" klicken.';

@Injectable({
  providedIn: 'root',
})
export class PendingChangesGuard
  implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(
    component: ComponentCanDeactivate
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return component.canDeactivate() ? true : confirm(leavePageMessage);
  }
}
