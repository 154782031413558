import { catchError, finalize, retry, retryWhen, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpXsrfTokenExtractor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {
  csrfToken: string;

  constructor(private tokenExtractor: HttpXsrfTokenExtractor) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.handle(req, next);
  }

  handle(
    req: HttpRequest<any>,
    next: HttpHandler,
    csrf: string = null
  ): Observable<HttpEvent<any>> {
    const headerName = 'X-Csrf-Token';
    let token = this.csrfToken
      ? this.csrfToken
      : (this.tokenExtractor.getToken() as string);

    if (csrf) {
      token = csrf;
    }

    if (token !== null) {
      console.log('Using %s as CSRF token', token);
      req = req.clone({ headers: req.headers.set(headerName, token) });
    }
    return next.handle(req).pipe(
      tap((httpEvent) => {
        if (httpEvent instanceof HttpResponse) {
          if (httpEvent?.headers && httpEvent?.headers?.get(headerName)) {
            this.csrfToken = httpEvent.headers.get(headerName);
          }
        }
      }),
      catchError((err: HttpErrorResponse) => {
        // check if a new CSRF token was in the response as the current seem to be invalid
        console.log('Error received: ' + err.status);
        if (
          err?.status === 428 && // look for 428 CSRF issue error code
          err?.headers &&
          err?.headers?.get(headerName)
        ) {
          this.csrfToken = err.headers.get(headerName);
          console.log(
            'New CSRF token found in error response: ' + this.csrfToken
          );
          if (!csrf) {
            return this.handle(req, next, this.csrfToken);
          }
        }

        // no CSRF issue, throw error to notify chain
        throw err;
      })
    );
  }
}
