import { Request } from './../request/request';
export class SearchResultWrapper {
  requests: Request[];

  totalHits: number;

  totalPages: number;

  page: number;
}
