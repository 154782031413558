import { UserService } from './../../../services/user/user.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit {
  isCollapsed = true;

  constructor(private userService: UserService) {}

  ngOnInit(): void {}

  isLoggedIn(): boolean {
    return this.userService.isLoggedIn();
  }
}
