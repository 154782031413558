import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PageLifecycleService } from './services/page-lifecycle/page-lifecycle.service';
import { SeoService } from './services/seo/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Farming365';

  margintTop = true;

  private routeSubscription: Subscription;

  constructor(
    private router: Router,
    private pageLifecycleService: PageLifecycleService,
    private seoService: SeoService
  ) {
    seoService.init();
  }

  ngOnInit(): void {
    this.routeSubscription = this.router.events.subscribe(() => {
      if (this.router.url === '/' || this.router.url === '/home') {
        this.margintTop = false;
      } else {
        this.margintTop = true;
      }
    });

    this.pageLifecycleService.startListening();
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    this.seoService.destroy();
  }
}
