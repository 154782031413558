import {
  Component,
  TemplateRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

class CookieConsent {
  constructor(
    public comfortCookies: boolean = false,
    public marketingCookies: boolean = false
  ) {}
}

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
})
export class CookieConsentComponent implements AfterViewInit {
  @ViewChild('content')
  contentTemplateref: TemplateRef<any>;

  hideConsentUrlParameter = 'cc=noopen';

  showSettings = false;

  comfortCookies = false;

  marketingCookies = false;

  private cookieName = 'cookie-consent';

  constructor(config: NgbModalConfig, private modalService: NgbModal) {
    // ensure that the modal cannot be closed by ESC key or by clicking on the backdrop
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngAfterViewInit(): void {
    let visible = true;

    const previousConsent = this.getCookie();
    if (previousConsent && previousConsent.length > 2) {
      visible = false;
      console.log(
        'Previous cookie consent found: %o',
        JSON.parse(previousConsent)
      );
    }

    if (visible) {
      visible = !(
        window.location.href.indexOf(this.hideConsentUrlParameter) > 0
      );
    }

    if (visible) {
      this.openDialog();
    }
  }

  openDialog(): void {
    const previousConsent = this.getCookie();
    if (previousConsent && previousConsent.length > 2) {
      try {
        const consent: CookieConsent = Object.assign(
          new CookieConsent(),
          JSON.parse(previousConsent)
        );
        this.marketingCookies = consent.marketingCookies;
        this.comfortCookies = consent.comfortCookies;
      } catch (err) {
        console.log('Unable to load previous cookie consent: %o', err);
      }
    }

    this.modalService
      .open(this.contentTemplateref, {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: 'subscription-modal',
      })
      .result.then(
        (reason) => {
          this.saveCookieConsentData(reason);
        },
        (reason) => {
          this.saveCookieConsentData(reason);
        }
      );
  }

  private saveCookieConsentData(reason: string): void {
    if (reason !== 'selected') {
      this.comfortCookies = true;
      this.marketingCookies = true;
    }

    const consent = new CookieConsent(
      this.comfortCookies,
      this.marketingCookies
    );
    console.log('Saving cookie consent: %o', consent);

    this.setCookie(this.cookieName, JSON.stringify(consent));
    this.showSettings = false;

    // call the GTag function from the index.html
    (window as any)?.startPageAnalyticsCallback();
  }

  private getCookie(name: string = this.cookieName): string {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        let result = c.substring(cookieName.length, c.length);
        if (!result) {
          result = '{}';
        }
        return result;
      }
    }
    return '{}';
  }

  private setCookie(
    name: string = this.cookieName,
    value: string,
    expireDays: number = 365,
    path: string = '/'
  ) {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }
}
