import { Component } from '@angular/core';

@Component({
  selector: 'app-text-picture',
  templateUrl: './text-picture.component.html',
  styleUrls: ['./text-picture.component.scss'],
})
export class TextPictureComponent {
  constructor() {}
}
