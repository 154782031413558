export const environment = {
  production: true,
  restApiBase:
    window.location.protocol +
    '//api.' +
    window.location?.hostname?.replace('www.', ''),
  markdownApiBase:
    window.location.protocol +
    '//' +
    window.location?.hostname?.replace('www.', ''),
  offlineMode: false,
  paymentLinkRandomizer: Math.random().toString(16).substring(2),
  paymentLinkRandomizerValidate: Math.random().toString(16).substring(2),
};
