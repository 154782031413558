import { StatusCodeHelper } from './../../http/util/status-code/status-code-helper';
import { Observable, EMPTY, BehaviorSubject, of } from 'rxjs';
import { CategoryTree } from './../../model/category-tree/category-tree';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { retry, map, catchError, take, single } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export const getCategoriesUrl = environment.restApiBase + '/v1/categories/all';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private currentCategoriesSubject: BehaviorSubject<CategoryTree>;

  constructor(private http: HttpClient) {
    this.currentCategoriesSubject = new BehaviorSubject<CategoryTree>(null);

    this.initializeCategories();
  }

  /**
   * Returns the category tree
   */
  findCategories(): Observable<CategoryTree> {
    return this.currentCategoriesSubject.asObservable();
  }

  findCategory(categoryId: number): Observable<CategoryTree> {
    if (categoryId || categoryId === 0) {
      const tree = this.currentCategoriesSubject.value;
      if (tree) {
        return of(tree.findCategoryById(categoryId));
      }
    }
    return this.findCategories().pipe(
      map((rootCategory) => rootCategory?.findCategoryById(categoryId))
    );
  }

  private initializeCategories(): void {
    console.log('Checking server for current categories');
    this.http
      .get<string>(getCategoriesUrl, { observe: 'response' })
      .pipe(
        retry(1),
        take(1),
        map((response) => {
          console.log('Checking category response for: %o', response.body);
          if (StatusCodeHelper.is2XX(response)) {
            this.currentCategoriesSubject.next(new CategoryTree(response.body));
          } else {
            this.currentCategoriesSubject.next(null);
          }
          return this.currentCategoriesSubject.value;
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('Handling error for category response');
          console.error(err);
          return EMPTY;
        })
      )
      .toPromise();
  }
}
