<div
  [class.visible]="firstImageLoaded"
  [class.invisible]="!firstImageLoaded"
  class="d-flex justify-content-center"
>
  <div class="carousel-container">
    <ngb-carousel [interval]="4000" *ngIf="images">
      <ng-template ngbSlide>
        <div class="img-wrapper">
          <picture
            app-image-renderer
            [imgSrc]="images[0]"
            [imgClass]="'carousel-img'"
            [style.min-height]="'350px'"
            [style.width]="'1000px'"
            [alt]="'Registrieren'"
            [useLazyLoading]="true"
            (loaded)="imageLoaded($event)"
          >
          </picture>
        </div>
        <div class="carousel-caption">
          <h2 routerLink="/auth/register">
            Registrieren, Anmelden, Inserieren in drei Schritten zum
            bestmöglichen Partner für Dienstleistungen rund um Agrar, Forst,
            Kommunal und GaLaBau.
          </h2>
        </div>
      </ng-template>
      <ng-container *ngIf="firstImageLoaded && images && images?.length > 1">
        <ng-template ngbSlide>
          <div class="img-wrapper">
            <picture
              app-image-renderer
              [imgSrc]="images[1]"
              [imgClass]="'carousel-img'"
              [style.min-height]="'350px'"
              [style.width]="'1000px'"
              [alt]="'Registrieren'"
            >
            </picture>
          </div>
          <div class="carousel-caption">
            <h2 routerLink="/auth/register">Im Frühjahr gehts wieder los...</h2>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="img-wrapper">
            <picture
              app-image-renderer
              [imgSrc]="images[2]"
              [imgClass]="'carousel-img'"
              [style.min-height]="'350px'"
              [style.width]="'1000px'"
              [alt]="'Registrieren'"
            >
            </picture>
          </div>
          <div class="carousel-caption">
            <h2 routerLink="/auth/register">
              Ihr bester Partner für alles rund um Agrar / Forst / Kommunal und
              GaLa-Bau.
            </h2>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="img-wrapper">
            <picture
              app-image-renderer
              [imgSrc]="images[3]"
              [imgClass]="'carousel-img'"
              [style.min-height]="'350px'"
              [style.width]="'1000px'"
              [alt]="'Registrieren'"
            >
            </picture>
          </div>
          <div class="carousel-caption">
            <h2 routerLink="/auth/register">
              Für beste Erträge und termingerechte Ausführung der
              Dienstleistungen.
            </h2>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="img-wrapper">
            <picture
              app-image-renderer
              [imgSrc]="images[4]"
              [imgClass]="'carousel-img'"
              [style.min-height]="'350px'"
              [style.width]="'1000px'"
              [alt]="'Registrieren'"
            >
            </picture>
          </div>
          <div class="carousel-caption">
            <h2 routerLink="/auth/register">
              Hier registrieren, damit auch der Mais perfekt wird.
            </h2>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="img-wrapper">
            <picture
              app-image-renderer
              [imgSrc]="images[5]"
              [imgClass]="'carousel-img'"
              [style.min-height]="'350px'"
              [style.width]="'1000px'"
              [alt]="'Registrieren'"
            >
            </picture>
          </div>
          <div class="carousel-caption">
            <h2 routerLink="/auth/register">
              Hier registrieren, um alle Facetten nutzen zu können.
            </h2>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="img-wrapper">
            <picture
              app-image-renderer
              [imgSrc]="images[6]"
              [imgClass]="'carousel-img'"
              [style.min-height]="'350px'"
              [style.width]="'1000px'"
              [alt]="'Registrieren'"
            >
            </picture>
          </div>
          <div class="carousel-caption">
            <h2 routerLink="/auth/register">
              Hier beitreten, damit Ihre Laune auch so gut ist wie der Garten
              aussieht.
            </h2>
          </div>
        </ng-template>
      </ng-container>
    </ngb-carousel>
  </div>
</div>
