<ng-template #mainTeaserText>
  <span>
    Das Portal zum Inserieren von Gesuchen und Auffinden von Dienstleistern rund
    um Feld / Forst / Kommunal und GaLa-Bau.
  </span>
</ng-template>

<div
  *ngIf="mobileResolution === false"
  class="desktop mt-0 p-0 col-12 d-block stage"
>
  <picture
    app-image-renderer
    [imgSrc]="'/assets/images/stage/stage-teaser.jpg'"
    [imgClass]="'col-12 p-0 m-0'"
    [alt]="'Stage Teaser'"
    [useLazyLoading]="false"
  >
  </picture>

  <div class="centered-top rounded">
    <h1 class="p-0">
      <span class="sr-only">Farming 365</span>
      <img
        class="logo-text"
        src="assets/images/logos/icon_text_colored.svg"
        alt="Farming 365"
        aria-hidden="true"
      />
    </h1>
    <p class="p-0 mb-0 rounded">
      <ng-container *ngTemplateOutlet="mainTeaserText"></ng-container>
    </p>
  </div>
</div>

<div
  *ngIf="mobileResolution === true"
  class="mobile mt-0 p-0 col-12 d-block stage"
>
  <h1 class="sr-only">Farming 365</h1>
  <picture
    app-image-renderer
    [imgSrc]="'/assets/images/stage/stage-teaser.avif'"
    [imgClass]="'col-12 p-0 m-0'"
    [alt]="'Stage Teaser'"
    [useLazyLoading]="false"
  >
  </picture>
  <div class="centered-top">
    <p class="p-auto mb-0 rounded">
      <ng-container *ngTemplateOutlet="mainTeaserText"></ng-container>
    </p>
  </div>
</div>
