export class CategoryTree {
  id: number;

  uid: string;

  name: string;

  categories: CategoryTree[];

  parent: CategoryTree;

  description: string;

  imageUrl: string;

  constructor(categoryTree: any) {
    this.buildData(categoryTree);
  }

  findCategoryById(id: number): CategoryTree {
    if (!id) {
      return null;
    }

    if (this.id === id) {
      return this;
    } else {
      let returnValue = null;
      if (this.categories) {
        Object.values(this.categories).forEach((value) => {
          const result = value.findCategoryById(id);
          if (result !== null) {
            returnValue = result;
          }
        });
      }
      return returnValue;
    }
  }

  private buildData(categoryTree: any) {
    if (categoryTree === undefined) {
      return;
    }

    this.id = categoryTree?.id;
    this.uid = categoryTree?.uid;
    this.imageUrl = categoryTree?.imageUrl;
    this.name = categoryTree?.name;
    this.description = categoryTree?.description;

    if (categoryTree.categories) {
      this.categories = [];
      Object.values(categoryTree.categories).forEach((value) => {
        const child = new CategoryTree(value);
        child.parent = this;
        this.categories.push(child);
      });
    }
  }
}
