import { Injectable } from '@angular/core';
import { LoremIpsum } from 'lorem-ipsum';

const lorem = new LoremIpsum({
  sentencesPerParagraph: {
    max: 8,
    min: 4
  },
  wordsPerSentence: {
    max: 16,
    min: 4
  }
});

@Injectable({
  providedIn: 'root'
})
export class LoremIpsumService {

  constructor() { }

  getLorem(): LoremIpsum {
    return lorem;
  }

  generateDoppelganger(textToLoremIpsumify: string): string {
    let result = '';
    if (textToLoremIpsumify) {
      result = this.getLorem().generateWords(textToLoremIpsumify.split(' ').length);

      while (result.length < textToLoremIpsumify.length) {
        result += ' ' + this.getLorem().generateWords(1);
      }

      while (result.length > textToLoremIpsumify.length) {
        result = result.slice(0, -1);
      }
    }
    return result.trim();
  }
}
