<nav class="navbar navbar-expand-xl navbar-dark">
  <a class="navbar-brand d-flex d-md-inline" routerLink="/">
    <svg>
      <use
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xlink:href="#logo-transparent"
      ></use>
    </svg>
  </a>
  <button
    class="navbar-toggler d-xl-none"
    type="button"
    (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="navbarsExampleDefault"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div
    class="collapse navbar-collapse"
    id="menu-navbarsExampleDefault"
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed"
  >
    <ul class="navbar-nav me-auto">
      <li
        class="nav-item"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a
          id="menu-home"
          class="nav-link"
          (click)="isCollapsed = true"
          routerLink=""
          >Home <span class="sr-only">(current)</span>
        </a>
      </li>
      <li class="nav-item" [routerLinkActive]="['active']">
        <a
          id="menu-my-profile"
          class="nav-link"
          (click)="isCollapsed = true"
          routerLink="/pages/profile"
          >Profil</a
        >
      </li>
      <li class="nav-item" [routerLinkActive]="['active']">
        <a
          id="menu-my-requests"
          class="nav-link"
          (click)="isCollapsed = true"
          routerLink="/pages/show/my/requests"
          >Meine Gesuche</a
        >
      </li>
      <li class="nav-item" [routerLinkActive]="['active']">
        <a
          id="menu-my-offers"
          class="nav-link"
          (click)="isCollapsed = true"
          routerLink="/pages/show/my/offers"
          >Meine Angebote</a
        >
      </li>
      <li class="nav-item" [routerLinkActive]="['active']">
        <a
          id="menu-my-watchlist"
          class="nav-link"
          (click)="isCollapsed = true"
          routerLink="/pages/show/watchlist"
          >Meine Watchliste</a
        >
      </li>
      <li class="nav-item" [routerLinkActive]="['active']">
        <a
          id="menu-about-us"
          class="nav-link"
          (click)="isCollapsed = true"
          routerLink="content/aboutus"
          >Idee</a
        >
      </li>
      <li class="nav-item" [routerLinkActive]="['active']">
        <a
          id="menu-faq"
          class="nav-link"
          (click)="isCollapsed = true"
          routerLink="content/faq"
          >FAQ</a
        >
      </li>
    </ul>

    <div
      [class.justify-content-center]="isLoggedIn()"
      [class.justify-content-start]="!isLoggedIn()"
      class="mt-5 mt-md-0 ms-auto justify-content-md-start d-flex ng-star-inserted"
    >
      <app-navigation-profile-widget
        *ngIf="isLoggedIn()"
        class="cursor-pointer shadow-none profile-nav"
        routerLink="/pages/profile"
        (click)="isCollapsed = true"
      ></app-navigation-profile-widget>

      <ul class="navbar-nav authentication">
        <li *ngIf="!isLoggedIn()" class="nav-item">
          <a
            class="nav-link fw-bold"
            routerLink="/auth/register"
            (click)="isCollapsed = true"
            >Registrieren</a
          >
        </li>
        <li *ngIf="!isLoggedIn()" class="nav-item">
          <a
            class="nav-link"
            (click)="isCollapsed = true"
            routerLink="/pages/profile"
            >Einloggen</a
          >
        </li>
        <li *ngIf="isLoggedIn()" class="nav-item logout">
          <a
            class="nav-link p-2"
            (click)="isCollapsed = true"
            routerLink="/auth/logout"
            >Ausloggen</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>
