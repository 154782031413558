import { Request, Type } from './../request/request';
import { NavigationExtras, ActivatedRoute } from '@angular/router';

export class SearchFilterConfiguration {
  private constructor() {}

  static createQueryAsNavigationExtrasByRequest(
    request: Request,
    hideInfos = false
  ): NavigationExtras {
    return {
      queryParams: {
        radius: request.searchRadius,
        from: request.getFromDateAsUnixTimestamp(),
        to: request.getToDateAsUnixTimestamp(),
        category: request.categoryId,
        type: request.type === Type.Offer ? Type.Request : Type.Offer, //switch for search
        latitude: request.geoLocation.getLatitude(),
        longitude: request.geoLocation.getLongitude(),
        hideInfos,
      },
    };
  }

  static createQueryAsStringByRequest(request: Request, page = 0): string {
    const extras = this.createQueryAsNavigationExtrasByRequest(request)
      .queryParams;
    const params = Object.keys(extras.queryParams);

    const queryStrings: string[] = [];
    for (const param of params) {
      queryStrings.push(
        encodeURIComponent(param) + '=' + encodeURIComponent(extras[param])
      );
    }
    queryStrings.push('page=' + page);
    return queryStrings.join('&');
  }

  static createQueryAsStringByActivatedRoute(
    route: ActivatedRoute,
    page = 0,
    sortOrder = null
  ): string {
    const urlQueryParams = route.snapshot.queryParamMap;

    const queryStrings: string[] = [];
    for (const key of urlQueryParams.keys) {
      const value = urlQueryParams.get(key);

      queryStrings.push(
        encodeURIComponent(key) + '=' + encodeURIComponent(value)
      );
    }
    queryStrings.push('page=' + page);
    if (sortOrder) {
      queryStrings.push('sorting=' + sortOrder);
    }
    return queryStrings.join('&');
  }
}
