/* eslint-disable no-console */
/* eslint-disable max-len */
import { PendingChangesGuard } from './services/pending-changes-guard/pending-changes.guard';
import { CsrfInterceptor } from './http/csrf/csrf.interceptor';
import { HttpRequestInterceptor } from './http/interceptor/http-request.interceptor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HttpMockRequestInterceptor } from './http/mock-interceptor/http-mock-request-interceptor.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import {
  HttpClientModule,
  HttpClientXsrfModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeDe);

// disable logging in Prod
if (environment.production) {
  console.log = () => false;
  console.info = () => false;
  console.debug = () => false;
}

@NgModule({
  declarations: [AppComponent, HomepageComponent],
  imports: [
    BrowserModule,
    HttpClientModule, // HttpClientModule needs to be placed after the BrowserModule!
    HttpClientXsrfModule.withOptions({
      cookieName: 'X-Csrf-Token',
      headerName: 'X-Csrf-Token',
    }),
    GoogleMapsModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    FormsModule,
    ImageCropperModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    SharedModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: environment.production
        ? HttpRequestInterceptor
        : HttpMockRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CsrfInterceptor,
      multi: true,
    },
    PendingChangesGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
