import { CachingService } from './../caching/caching.service';
import { StatusCodeHelper } from './../../http/util/status-code/status-code-helper';
import { environment } from './../../../environments/environment';
import { Company } from './../../model/company/company';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { User } from 'src/app/model/user/user';
import { map, catchError } from 'rxjs/operators';
import { EMPTY, BehaviorSubject, of, Observable } from 'rxjs';

const baseUrl = environment.restApiBase + '/v1/user';
export const loginUrl = baseUrl + '/login';
export const registerUrl = baseUrl + '/register';
export const getUserUrl = baseUrl + '/get';
export const validateEmailUrl = baseUrl + '/validate/email';
export const saveUserUrl = baseUrl + '/save';
export const requestResetPasswordUrl = baseUrl + '/reset/password/request';
export const resetPasswordUrl = baseUrl + '/reset/password';
export const logoutUrl = baseUrl + '/logout';
export const deleteAccountUrl = baseUrl + '/delete/account';

/*
 * JWT cache keys
 */
export const jwtTtlCacheKey = 'jwt_token_ttl';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private currentUserSubject: BehaviorSubject<User>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private cachingService: CachingService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(null);

    if (cachingService.getFromCache(jwtTtlCacheKey)) {
      const ttl = new Date(
        +cachingService.getFromCache(jwtTtlCacheKey)
      ).getTime();
      if (new Date().getTime() + 1000 * 60 * 60 < ttl) {
        // JWT is still good to use --> load user with old token
        console.log('Refreshing user');
        this.fetchUserData().then((user) =>
          console.log('Loaded user: %o', user)
        );
      } else {
        // JWT is pretty old --> force the user to reenter login credentials
        console.log('Deleting old JWT token');
        cachingService.removeFromCache(jwtTtlCacheKey);
      }
    } else {
      console.log('No old JWT token found');
    }
  }

  private get user(): User {
    return this.currentUserSubject.value;
  }

  private set user(user: User) {
    this.currentUserSubject.next(user);
  }

  /**
   * Checks if the user is currently logged in
   */
  isLoggedIn(): boolean {
    return this.currentUserSubject.value ? true : false;
  }

  /**
   * Starts the login on the server
   *
   * @param email the emails
   * @param password  the password
   */
  doLogin(
    email: string,
    password: string,
    rememberLogin = false
  ): Promise<User> {
    console.log('Starting login for %s', email);

    return this.http
      .post<string>(
        loginUrl,
        {
          email,
          password,
          stayLoggedIn: rememberLogin,
        },
        {
          observe: 'response',
        }
      )
      .pipe(
        map((response) => {
          console.log('Checking login response for: %o', response);
          const user = this.extractUser(response);
          if (user) {
            const ttl = response.headers.get('Expires');
            this.cachingService.addToCache(jwtTtlCacheKey, ttl);

            console.log('Found JWT token TTL: %s', ttl);
          }
          return user;
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('Handling error for login response');
          console.error(err);
          this.clear();
          return EMPTY;
        })
      )
      .toPromise();
  }

  /**
   * Fetches the user data from the server
   */
  fetchUserData(): Promise<User> {
    return this.http
      .get<string>(getUserUrl, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          console.log('Checking fetch user response for: %o', response);
          const user = this.extractUser(response);
          return user;
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('Handling error for login response');
          console.error(err);
          this.clear();
          return EMPTY;
        })
      )
      .toPromise();
  }

  /**
   * Starts the login on the server
   *
   * @param email the emails
   * @param password  the password
   */
  doRegister(user: User): Promise<User> {
    console.log('Starting login for %o', user);

    return this.http
      .post<string>(registerUrl, user, { observe: 'response' })
      .pipe(
        map((response) => {
          console.log('Checking register response for: %o', response);
          return this.extractUser(response, false);
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('Handling error for register response');
          console.error(err);
          throw new Error(err?.error);
        })
      )
      .toPromise();
  }

  validateEmail(email: string): Promise<boolean> {
    console.log('Starting email check for %s', email);

    return this.http
      .post<boolean>(validateEmailUrl, email, { observe: 'response' })
      .pipe(
        map((response) => {
          console.log('Checking email check response for: %o', response);
          return StatusCodeHelper.is2XX(response);
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('Handling error for email check response');
          console.error(err);
          if (StatusCodeHelper.is4XX(err)) {
            return of(false);
          }
          throw new Error(err?.error);
        })
      )
      .toPromise();
  }

  /**
   * Redirects the user back to the original page
   *
   * @param redirect the page to redirect to
   */
  doRedirect(redirect: string = '/pages/profile', errorRedirect = '/error') {
    // prevent URL jacking by allowing only redirects to this domain
    if (this.isLoggedIn() && redirect?.startsWith('/')) {
      this.router.navigateByUrl(redirect);
    } else if (errorRedirect?.startsWith('/')) {
      this.router.navigateByUrl(errorRedirect);
    } else {
      this.router.navigateByUrl('/');
    }
  }

  /**
   * Logs the user out
   */
  doLogout(): Promise<boolean> {
    this.clear();

    return this.http
      .post<string>(logoutUrl, {}, { observe: 'response' })
      .pipe(
        map((response) => {
          console.log('Checking logout response for: %o', response);
          if (StatusCodeHelper.is2XX(response)) {
            return true;
          }
          throw new Error('Invalid response from server');
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('Handling error for logout response');
          console.error(err);
          throw new Error('Invalid response from server');
        })
      )
      .toPromise();
  }

  requestResetPassword(email: string): Promise<boolean> {
    if (email) {
      console.log('Requesting password reset for user %s', email);

      return this.http
        .post<string>(
          requestResetPasswordUrl,
          { username: email },
          { observe: 'response' }
        )
        .pipe(
          map((response) => {
            console.log(
              'Checking request reset password response for: %o',
              response
            );
            if (StatusCodeHelper.is2XX(response)) {
              return true;
            }
            throw new Error('Invalid response from server');
          }),
          catchError((err: HttpErrorResponse) => {
            console.log(
              'Handling error for request reset password response. Code = %s',
              err.status
            );
            console.error(err);
            if (err.status === 412) {
              const error = new Error(
                'Die angegebene E-Mailadresse ist uns leider nicht bekannt'
              );
              error.name = '' + err.status;
              throw error;
            }
            throw new Error('Invalid response from server');
          })
        )
        .toPromise();
    }
    return null;
  }

  resetPassword(password: string, token?: string): Promise<boolean> {
    if (this.user || token) {
      console.log('Setting new password for user %s', this.user?.email);

      return this.http
        .post<string>(
          resetPasswordUrl,
          {
            password,
            token: token ? token : '',
          },
          { observe: 'response' }
        )
        .pipe(
          map((response) => {
            console.log('Checking reset password response for: %o', response);
            if (StatusCodeHelper.is2XX(response)) {
              return true;
            }
            throw new Error('Invalid response from server');
          }),
          catchError((err: HttpErrorResponse) => {
            console.log('Handling error for reset password response');
            console.error(err);
            throw new Error('Invalid response from server');
          })
        )
        .toPromise();
    }
    return null;
  }

  /**
   * Returns the current user
   */
  getUser(): User {
    return this.user;
  }

  getUserObservable(): Observable<User> {
    return this.currentUserSubject.asObservable();
  }

  /**
   * Deletes the account
   */
  deleteAccount(): Promise<boolean> {
    console.log('Accout deletion requested for user %s', this.user?.email);

    return this.http
      .post<string>(deleteAccountUrl, {}, { observe: 'response' })
      .pipe(
        map((response) => {
          console.log('Checking delete account response for: %o', response);
          if (StatusCodeHelper.is2XX(response)) {
            return true;
          }
          throw new Error('Invalid response from server');
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('Handling error for delete account response');
          console.error(err);
          throw new Error('Invalid response from server');
        })
      )
      .toPromise();
  }

  /**
   * Saves the user
   *
   * @param user the user to save
   */
  saveUser(user: User): Promise<User> {
    console.log('Saving user %s', user?.email);

    return this.http
      .post<string>(saveUserUrl, user, { observe: 'response' })
      .pipe(
        map((response) => {
          console.log('Checking save response for: %s', response);
          return this.extractUser(response);
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('Handling error for login response');
          console.error(err);
          this.clear();
          return EMPTY;
        })
      )
      .toPromise();
  }

  /**
   * Extracts the user form the Http response and stores it
   *
   * @param response
   */
  private extractUser(response: HttpResponse<any>, storeUser = true): User {
    let user: User = null;
    if (StatusCodeHelper.is2XX(response)) {
      console.log(response.body);
      user = Object.assign(new User(), response.body);
      user.company = Object.assign(new Company(), response.body.company);
      user.company.creationDate = Object.assign(
        new Date(),
        user.company.creationDate
      );
    }

    if (storeUser) {
      this.user = user;
    }

    if (!StatusCodeHelper.is2XX(response)) {
      throw new Error('Invalid response from server');
    }
    return user;
  }

  /**
   * Clears the cache
   */
  private clear(): void {
    this.user = null;
    this.cachingService.removeFromCache(jwtTtlCacheKey);
  }
}
