<div class="mt-5 mb-5 grid-root">
  <div class="container pt-3 pb-3">
    <div #text1 class="row">
      <div class="col-lg-6 grid p-3 grid-text">
        <h2>Was erwartet mich bei der neuen Plattform?</h2>
        <br />
        <p class="mb-0">
          Effiziente Nutzung von einem breiten Dienstleister-Angebot. Ich
          brauche nicht einzeln die Firmen durchsuchen und kontaktieren. Ich
          bekomme auch Angebote von Dienstleistern, auf die ich sonst vielleicht
          nicht aufmerksam geworden wäre.
        </p>
        <p class="mb-0">
          Somit kann ich unter mehreren Anbietern auswählen und im besten Fall
          noch Geld sparen, oder die Dienstleistung zu einem früheren oder
          zeitlich besser passenden Zeitpunkt erledigen lassen.
        </p>
        <p class="mb-0">
          Das Ganze wird zu einem kleinen Unkosten-Beitrag ermöglicht, der fix
          ist, bezogen auf den Zeitraum des aktiven Profils. Es gibt keine
          Abhängigkeit zu Größe und Umfang des Auftrages / der Dienstleistung.
        </p>
        <p class="mb-0">
          Wir betrachten uns als "Möglichmacher", damit Anbieter und Kunde
          zusammenfinden. Neudeutsch eine Win-Win-Situation für beide Seiten.
          Das daraus lange und feste Partnerschaften entstehen ist beabsichtigt
          und gewünscht. Wie bei einem guten Restaurant, wenn man zufrieden war,
          geht man gerne wieder dort hin.
        </p>
      </div>
      <div lazyLoad class="col-lg-6 grid p-0 picture-box picture-1"></div>
    </div>

    <div #text2 class="row">
      <div lazyLoad class="col-lg-6 grid p-0 picture-box picture-2"></div>
      <div class="col-lg-6 grid p-3 grid-text">
        <h2>Welche Vorteile habe ich als Unternehmer?</h2>
        <br />
        <p class="mb-0">
          Um in der Disposition meines Lohnunternehmens, in der Planung meines
          Betriebes im Bereich Agrar, Forst, Kommunal und GaLaBau eine bessere
          Auslastung und Planungssicherheit zu haben, ist es wichtig die
          Anfragen, die Ideen und Wünsche meiner Kundschaft zu kennen. Hierin
          besteht einer der Vorteile, dass ich eine Übersicht habe, welche
          Kunden wo, wann und welche Dienstleistungen benötigen.
        </p>
        <p class="mb-0">
          Aus diesem ersten Kontakt lassen sich Aufträge generieren, Verträge
          schließen oder auch kurzfristig auf Bedarf oder freie Kapazitäten
          reagieren.
        </p>
        <p class="mb-0">
          Dies ist alles nach Postleitzahl sortiert, dass ich entscheiden kann
          in welchem Radius ich arbeiten möchte.
        </p>
        <p class="mb-0">
          Die Abrechnung der erbrachten Arbeit passiert immer zwischen Kunde und
          Dienstleister und ist komplett unabhängig von dieser Plattform.
        </p>
      </div>
    </div>
  </div>
</div>
