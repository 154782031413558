<div
  *ngIf="user && user.company"
  class="cursor-pointer me-2 shadow-none navbar p-0 justify-content-md-start justify-content-center d-flex"
>
  <img
    class="rounded-circle"
    [src]="user?.company?.logo | linkRenderer"
    alt="{{ user.company.name }}"
    title="{{ user.company.name }}"
  />
  <p class="pt-2 ms-1">{{ user.firstName }} {{ user.lastName }}</p>
</div>
