import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-find-offer',
  templateUrl: './find-offer.component.html',
  styleUrls: ['./find-offer.component.scss'],
})
export class FindOfferComponent {
  @Input()
  showOffer = true;

  @Input()
  showRequest = true;

  @Input()
  useContainerDiv = true;

  @Input()
  usePadding = true;

  @Input()
  useAnimations = true;

  constructor() {}
}
