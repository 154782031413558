import {
  trigger,
  state,
  transition,
  style,
  animate,
} from '@angular/animations';
import { ToastService } from './../../services/toast/toast.service';
import { Component, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-toasts', // <app-toats> is rendered in the <app-header> component
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('animation', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', animate(`10000ms ease-out`)),
      transition(':leave', animate(`10000ms ease-in`)),
    ]),
  ],
})
export class ToastComponent {
  constructor(public toastService: ToastService) {}

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
